import map from "lodash/map";

const DEFAULT_ZOOM_HOURS = 7 * 24;

const SIDE_PANEL_WIDTHS = 160;
const EXPANDED_SIDE_PANEL_WIDTHS = 564;

function sidePanelWidth(expanded) {
  return expanded ? EXPANDED_SIDE_PANEL_WIDTHS : SIDE_PANEL_WIDTHS;
}

const MIN_TICK_WIDTH = 50;
function buildTimeResolution() {
  return {
    unit: "minute",
    increment: 5,
  };
}

function buildTopRowDayHeader(zoomable = true) {
  return {
    unit: "day",
    headerCellCls: zoomable ? "bryntumDateHeaderStyle zoomableDateHeader" : "bryntumDateHeaderStyle",
    dateFormat: "DDD | ddd MMM D",
  };
}

function buildSecondRowDayHeader() {
  return {
    unit: "day",
    headerCellCls: "bryntumTimeHeaderStyle",
    dateFormat: "ddd MMM D",
  };
}

function buildMinuteRowHeader({ increment = 30 } = {}) {
  return {
    unit: "minute",
    headerCellCls: "bryntumTimeHeaderStyle",
    dateFormat: "h:mm A",
    increment,
  };
}

function buildHourRowHeader({ increment = 1 } = {}) {
  return {
    unit: "hour",
    headerCellCls: "bryntumTimeHeaderStyle",
    dateFormat: "h A",
    increment,
  };
}

function getTickWidth(ticks, expanded) {
  return Math.max((window.innerWidth - sidePanelWidth(expanded)) / ticks, MIN_TICK_WIDTH);
}

const defaultPresetProperties = (ticks) => ({
  tickWidth: getTickWidth(ticks, false),
  tickHeight: 50,
  displayDateFormat: "ddd HH:mm",
  defaultSpan: 7,
  timeResolution: buildTimeResolution(),
});

const ZoomLevels = [
  {
    index: 0,
    timeIncrement: 10,
    label: "2 hr",
    hours: 2,
    preset: {
      ...defaultPresetProperties(12),
      ticks: 12,
      id: "2hr",
      level: 0,
      headers: [buildTopRowDayHeader(false), buildMinuteRowHeader({ increment: 10 })],
    },
  },
  {
    index: 1,
    timeIncrement: 30,
    label: "4 hr",
    hours: 4,
    preset: {
      ...defaultPresetProperties(8),
      ticks: 8,
      id: "4hr",
      level: 1,
      headers: [buildTopRowDayHeader(), buildMinuteRowHeader()],
    },
  },
  {
    index: 2,
    timeIncrement: 30,
    label: "8 hr",
    hours: 8,
    preset: {
      ...defaultPresetProperties(16),
      ticks: 16,
      id: "8hr",
      level: 2,
      headers: [buildTopRowDayHeader(), buildMinuteRowHeader()],
    },
  },
  {
    index: 3,
    timeIncrement: 60,
    label: "12 hr",
    hours: 12,
    preset: {
      ...defaultPresetProperties(12),
      ticks: 12,
      id: "12hr",
      level: 3,
      headers: [buildTopRowDayHeader(), buildHourRowHeader()],
    },
  },
  {
    index: 4,
    timeIncrement: 60,
    label: "1 day",
    hours: 24,
    preset: {
      ...defaultPresetProperties(24),
      ticks: 24,
      id: "1day",
      level: 4,
      headers: [buildTopRowDayHeader(), buildHourRowHeader()],
    },
  },
  {
    index: 5,
    timeIncrement: 60,
    label: "3 days",
    hours: 72,
    preset: {
      ...defaultPresetProperties(24),
      ticks: 24,
      id: "3days",
      level: 5,
      headers: [buildTopRowDayHeader(), buildHourRowHeader({ increment: 3 })],
    },
  },
  {
    index: 6,
    timeIncrement: 60,
    label: "5 days",
    hours: 120,
    preset: {
      ...defaultPresetProperties(20),
      ticks: 20,
      id: "5days",
      level: 6,
      headers: [buildTopRowDayHeader(), buildHourRowHeader({ increment: 6 })],
    },
  },
  {
    index: 7,
    timeIncrement: 60,
    label: "1 week",
    hours: 168,
    preset: {
      ...defaultPresetProperties(28),
      ticks: 28,
      id: "1week",
      level: 7,
      headers: [buildTopRowDayHeader(), buildHourRowHeader({ increment: 6 })],
    },
  },
  {
    index: 8,
    timeIncrement: 60,
    label: "2 weeks",
    hours: 336,
    preset: {
      ...defaultPresetProperties(14),
      ticks: 14,
      id: "2weeks",
      level: 8,
      headers: [
        {
          unit: "week",
          dateFormat: "WWp",
          headerCellCls: "bryntumDateHeaderStyle",
        },
        buildSecondRowDayHeader(),
      ],
    },
  },
];

const maxZoomLevel = ZoomLevels.length - 1;

function zoomLevelMapping(zoomLevel) {
  return ZoomLevels[zoomLevel];
}

function zoomLevelDurationInHours(level) {
  return ZoomLevels[level]?.hours || ZoomLevels[0]?.hours;
}

function presetId(zoomLevel) {
  return zoomLevelMapping(zoomLevel)?.preset?.id || defaultViewPresetId;
}

function timeIncrement(zoomLevel) {
  return zoomLevelMapping(zoomLevel)?.timeIncrement || 60;
}

function calculateZoomOptions() {
  const options = map(ZoomLevels, (option, level) => ({ value: level, label: option.label }));

  return options.reverse();
}

const zoomOptions = calculateZoomOptions();

function calculateViewPresets() {
  return ZoomLevels.map((level) => level.preset).reverse();
}

const viewPresets = calculateViewPresets();

const defaultViewPresetId = "1week";

function calculateDefaultZoomLevel() {
  return ZoomLevels.findIndex((level) => level.preset.id === defaultViewPresetId);
}

const defaultZoomLevel = calculateDefaultZoomLevel();

export {
  DEFAULT_ZOOM_HOURS,
  defaultViewPresetId,
  defaultZoomLevel,
  getTickWidth,
  maxZoomLevel,
  presetId,
  timeIncrement,
  viewPresets,
  zoomLevelDurationInHours,
  zoomOptions,
};
