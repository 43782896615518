import React from "react";
import Heading from "./Heading";
import HeaderWrapper from "./HeaderWrapper";
import WorkBlock from "domain/WorkBlock";

export default function WorkBlockHeader({ workBlockLayout, block, code }) {
  const description = WorkBlock.description(block, workBlockLayout);

  return (
    <HeaderWrapper>
      {description && <Heading>{description}</Heading>}
      {description && <span>&nbsp;•&nbsp;</span>}
      <span>{code}</span>
    </HeaderWrapper>
  );
}
