import React from "react";
import { DropdownButton, DropdownMenu, Flex, Icon, PrimaryButton, QuietButton, theme } from "@nulogy/components";
import styled, { css } from "styled-components";

const mainButtonStyles = css`
  margin-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: 90px;
  justify-content: left;
  font-size: 14px;
  height: 24px;
`;

const QuietMainButton = styled(QuietButton)`
  ${mainButtonStyles};
  background-color: ${theme.colors.whiteGrey};
`;

const PrimaryMainButton = styled(PrimaryButton)`
  ${mainButtonStyles}
`;

const triggerButtonStyles = css`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 24px;
  margin-left: 1px;
`;

const QuietTriggerButton = styled(QuietButton)`
  ${triggerButtonStyles};
  background-color: ${theme.colors.whiteGrey};
`;

const PrimaryTriggerButton = styled(PrimaryButton)`
  ${triggerButtonStyles};
`;

export default function SplitButton({ disabled, onChange, options, selectedOption, quiet }) {
  const MainButton = quiet ? QuietMainButton : PrimaryMainButton;
  const TriggerButton = quiet ? QuietTriggerButton : PrimaryTriggerButton;

  return (
    <Flex>
      <MainButton size="small" onClick={() => onChange(selectedOption)} disabled={disabled}>
        {options.find((option) => option.value === selectedOption).label}
      </MainButton>
      <DropdownMenu
        placement="bottom-end"
        disabled={disabled}
        width="116px"
        trigger={() => (
          <TriggerButton size="small" className="spec-split-trigger-button">
            <Icon icon="downArrow" size="16px" />
          </TriggerButton>
        )}
      >
        {options.map((option) => (
          <DropdownButton
            onClick={() => {
              onChange(option.value);
            }}
            key={option.value}
          >
            {option.label}
          </DropdownButton>
        ))}
      </DropdownMenu>
    </Flex>
  );
}
