import { Box, Flex, Icon, Text, Tooltip } from "@nulogy/components";
import styled from "styled-components";
import React from "react";

const Label = styled(Flex)`
  align-items: center;
  transform: none !important;
  -webkit-transform: none !important;
`;

export default function LabelWithTooltip({ label, tooltip, tooltipPlacement = "bottom" }) {
  return (
    <Label>
      <Text style={{ textWrap: "pretty" }}>
        {label}
        <Box display="inline-block" height="24px" ml="half" verticalAlign="middle">
          <Tooltip placement={tooltipPlacement} tooltip={tooltip}>
            <Icon icon="info" color="black" size="20px" />
          </Tooltip>
        </Box>
      </Text>
    </Label>
  );
}
