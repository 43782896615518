import React from "react";
import { Box } from "@nulogy/components";
import upperFirst from "lodash/upperFirst";
import noop from "lodash/noop";
import { useQuery } from "@apollo/client";
import EditNotes from "./EditNotes";
import ScheduledWorkBlockDetailsContainer from "./ScheduledWorkBlockDetailsContainer";
import Field from "components/common/Field";
import WorkOrderCard from "components/pages/schedule/workOrderCards/WorkOrderCard";
import PackmanagerWorkOrderLink from "components/pages/schedule/PackmanagerWorkOrderLink";
import {
  formatBoolean,
  formatDate,
  formatDateTime,
  formatPercentage,
  formatProductionRate,
  formatQuantity,
} from "components/helpers/format";
import CollapsibleSection from "components/common/CollapsibleSection";
import Section from "components/common/Section";
import WorkOrder from "domain/WorkOrder";
import { GetLayoutSetting } from "hooks/apollo/customerSettings/customerSettings.gql";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";

export default function WorkOrderDetailsView({ initialSectionOpenState, readonly, toggleSectionState, workOrder }) {
  const {
    code,
    customWorkOrderFieldName,
    customerName,
    description,
    due,
    externalId,
    externalUuid,
    id: workOrderId,
    itemCode,
    itemDescription,
    lineTypePreferencePrimary,
    lineTypePreferenceSecondary,
    notes,
    performance,
    producedQuantity,
    productionLastUpdatedAt,
    productionRateDependsOnNumberOfPeople,
    quantity,
    reference1,
    reference2,
    reference3,
    scheduledQuantity,
    standardPeople,
    standardProductionRate,
    status,
    unitOfMeasure,
  } = workOrder;

  const applicationContext = useApplicationContext();
  const { data: layoutSettingData, loading: layoutSettingLoading } = useQuery(GetLayoutSetting);

  if (applicationContext.loading || layoutSettingLoading) return null;

  const {
    settings: { showCustomWorkOrderFields },
  } = applicationContext;

  const formattedProductionRate = formatProductionRate({ productionRate: standardProductionRate, unitOfMeasure });
  const formattedQuantity = formatQuantity(quantity);
  const formattedProducedQuantity = formatQuantity(producedQuantity);
  const formattedScheduledQuantity = WorkOrder.isScheduled(workOrder)
    ? formattedQuantity
    : formatQuantity(scheduledQuantity);

  if (layoutSettingLoading) return null;

  return (
    <Box marginBottom="40px" data-testid="work-order-details-root">
      <Box marginY="x2">
        <WorkOrderCard workOrder={workOrder} layoutSettingData={layoutSettingData} onToggleExpandWorkOrder={noop} />
      </Box>
      <Section title="Notes">
        <EditNotes workOrderId={workOrderId} notes={notes} readonly={readonly} />
      </Section>

      <CollapsibleSection
        isOpen={initialSectionOpenState.scheduledBlocks}
        onToggle={toggleSectionState("scheduledBlocks")}
        title="Scheduled blocks"
        className="spec-work-order-details-scheduled-blocks"
      >
        <ScheduledWorkBlockDetailsContainer workOrder={workOrder} />
      </CollapsibleSection>

      <CollapsibleSection
        isOpen={initialSectionOpenState.workOrderInformation}
        onToggle={toggleSectionState("workOrderInformation")}
        title="Work order information"
      >
        <Field labelText="Work order code">
          <PackmanagerWorkOrderLink code={code} externalUuid={externalUuid} />
        </Field>
        <Field labelText="Work order ID">{externalId}</Field>
        <Field labelText="Work order description">{description}</Field>
        <Field labelText="Item code">{itemCode}</Field>
        <Field labelText="Item description">{itemDescription}</Field>
        <Field labelText="Quantity">{`${quantity} ${unitOfMeasure}`}</Field>
        <Field labelText="Unit of measure">{unitOfMeasure}</Field>
        <Field labelText="Customer">{customerName}</Field>
        <Field labelText="Due date">{formatDate(due)}</Field>
        <Field labelText="Work order status">{upperFirst(status)}</Field>
        <Field labelText="Primary line type preference">{lineTypePreferencePrimary}</Field>
        <Field labelText="Secondary line type preference">{lineTypePreferenceSecondary}</Field>
        {showCustomWorkOrderFields && (
          <Field labelText="Custom work order field" data-testid="work-order-details-custom-work-order-field-name">
            {customWorkOrderFieldName}
          </Field>
        )}
      </CollapsibleSection>

      <CollapsibleSection
        isOpen={initialSectionOpenState.productionInformation}
        onToggle={toggleSectionState("productionInformation")}
        title="Production information"
      >
        <Field labelText="Last production recorded at">{formatDateTime(productionLastUpdatedAt)}</Field>
        <Field labelText="Quantity scheduled">
          {formattedScheduledQuantity}/{formattedQuantity} {unitOfMeasure}
        </Field>
        <Field labelText="Quantity produced">
          {formattedProducedQuantity}/{formattedQuantity} {unitOfMeasure}
        </Field>
        <Field labelText="Standard production rate">{formattedProductionRate}</Field>
        <Field labelText="Performance">{formatPercentage(performance)}</Field>
        <Field labelText="Production rate depends on number of people">
          {formatBoolean(productionRateDependsOnNumberOfPeople)}
        </Field>
        <Field labelText="Standard people">{formatQuantity(standardPeople)}</Field>
        <Field labelText="Reference 1">{reference1}</Field>
        <Field labelText="Reference 2">{reference2}</Field>
        <Field labelText="Reference 3">{reference3}</Field>
      </CollapsibleSection>
    </Box>
  );
}
