import React from "react";
import { Box, DatePicker, Flex, IconicButton, QuietButton, Select, Text, theme } from "@nulogy/components";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import ZoomLevelSelector from "./ZoomLevelSelector";
import SplitButton from "components/controls/SplitButton";
import { defaultZoomLevel, zoomOptions } from "config/zoomConfig";
import { useSchedulerState } from "hooks/state";
import DateTime from "utils/DateTime";
import {
  QuickNavigationOption,
  QuickNavigationUserOptionValue,
  QuickNavigationUserOptions,
} from "config/quickNavigationOptions";

const BorderlessDatePickerWrapper = styled.span`
  display: inline-block;
  position: relative;

  .react-datepicker-popper {
    z-index: 100000 !important;
  }

  input {
    margin-top: 0;
    border: none;
    padding-left: ${theme.space.x4};
    width: 92px;
  }

  svg.nds-icon--calendarToday {
    left: ${theme.space.x1};
  }
`;

const RangeTypeSelector = styled(Select)`
  width: 108px;

  @media only screen and (max-width: 825px) {
    width: 100%;
  }

  border: none;
  div.ndsSelect__control {
    border: 1px solid transparent;
    margin-top: -1px;
    margin-bottom: -1px;
    border-top-color: ${theme.colors.grey};
    border-bottom-color: ${theme.colors.grey};
    border-radius: 0;
  }
  div.ndsSelect__control.ndsSelect__control--is-focused {
    border: 1px solid ${theme.colors.blue};
    border-radius: ${theme.sizes.half};
  }
`;

const BorderedContainer = styled(Flex)`
  border-radius: 4px 4px 4px 4px;
  border: 1px solid ${theme.colors.grey};
  margin-right: ${theme.space.x2};

  @media only screen and (max-width: 825px) {
    margin-right: 0;
  }

  width: 100%;
`;

const HiddenText = styled(Text)`
  visibility: hidden;
`;

const EndDateBox = styled(Box)`
  min-width: 56px;
  width: 100%;
`;

const Divider = () => <Box width="1px" height="24px" borderRight={`1px solid ${theme.colors.grey}`} />;

const FlexContainer = styled(Flex)`
  @media only screen and (max-width: 825px) {
    flex-direction: column;
    align-items: start;
    gap: 8px;
    flex-grow: 1;
  }
`;

const RangeScrollFlex = styled(Flex)`
  width: 100%;
`;

function formatDate(endDate) {
  return DateTime.format(endDate, "MMM DD");
}

export default function ZoomPicker({
  disabled,
  onDateChange,
  onQuickNavigationChange,
  onZoomLevelChange,
  zoomLevel,
  visibleTimeRange,
}) {
  const {
    state: { quickNavigationOption },
  } = useSchedulerState();
  const handleResetZoom = () => {
    onZoomLevelChange(defaultZoomLevel);
  };

  let isOnQuickNavigationDate;
  switch (quickNavigationOption) {
    case QuickNavigationUserOptionValue.WEEK_START:
      isOnQuickNavigationDate = DateTime.isStartOfWeek(visibleTimeRange.startDate);
      break;
    case QuickNavigationUserOptionValue.TODAY:
    case QuickNavigationUserOptionValue.NOW:
      isOnQuickNavigationDate = DateTime.isToday(visibleTimeRange.startDate);
      break;
    default:
      Sentry.captureMessage(`Unexpected quickNavigationOption: : ${quickNavigationOption}`, {});
      isOnQuickNavigationDate = false;
  }

  const isDatesTheSame = DateTime.isSameDate(visibleTimeRange.startDate, visibleTimeRange.endDate);

  const EndDateText = isDatesTheSame ? HiddenText : Text;

  const handlePreviousClick = () => {
    onQuickNavigationChange(QuickNavigationOption.BACK);
  };
  const handleNextClick = () => {
    onQuickNavigationChange(QuickNavigationOption.FORWARD);
  };

  return (
    <FlexContainer alignItems="flex-start">
      <BorderedContainer gap="x1" px="x1" alignItems="center">
        <SplitButton
          selectedOption={quickNavigationOption}
          disabled={disabled}
          options={QuickNavigationUserOptions}
          onChange={onQuickNavigationChange}
          quiet={isOnQuickNavigationDate}
        />

        <BorderlessDatePickerWrapper>
          <DatePicker
            className="spec-current-date-picker"
            name="placeholder"
            selected={new Date(visibleTimeRange.startDate)}
            dateFormat={["MMM dd", "MMM d, yyyy"]}
            onChange={onDateChange}
          />
        </BorderlessDatePickerWrapper>
        <EndDateText color={theme.colors.midGrey}>–</EndDateText>
        <EndDateBox ml="x1">
          <EndDateText className="spec-end-date-text" color={theme.colors.midGrey}>
            {formatDate(visibleTimeRange.endDate)}
          </EndDateText>
        </EndDateBox>

        <Divider />
        <IconicButton
          onClick={handlePreviousClick}
          icon="leftArrow"
          className="spec-previous-date-click"
          disabled={disabled}
          mr="0px"
        />
        <IconicButton
          ml="0px"
          onClick={handleNextClick}
          icon="rightArrow"
          className="spec-next-date-click"
          disabled={disabled}
        />
      </BorderedContainer>

      <RangeScrollFlex flexGrow={1} width="100%">
        <BorderedContainer gap="x1" px="x1" alignItems="center">
          <RangeTypeSelector
            disabled={disabled}
            maxHeight="400px"
            value={zoomLevel}
            options={zoomOptions}
            id="date-range-type"
            onChange={onZoomLevelChange}
            className="spec-zoom-select"
          />
          <Divider />
          <ZoomLevelSelector disabled={disabled} onZoomLevelChange={onZoomLevelChange} zoomLevel={zoomLevel} />
          <Divider />

          <QuietButton disabled={disabled} size="small" onClick={handleResetZoom}>
            Reset
          </QuietButton>
        </BorderedContainer>
      </RangeScrollFlex>
    </FlexContainer>
  );
}
