import React from "react";
import { Box, Icon, theme } from "@nulogy/components";
import styled from "styled-components";
import BlockCellDetails from "./BlockCellDetails";
import BlockCellName from "./BlockCellName";
import WorkBlockHeader from "./WorkBlockHeader";

const WorkBlockPreviewContainer = styled.div({
  border: `solid 1px ${theme.colors.darkBlue}`,
  borderRadius: theme.sizes.half,
  padding: theme.sizes.half,
  fontSize: theme.fontSizes.smaller,
  minWidth: 280,
  position: "relative",
});

const BlockMenu = styled(Icon)({
  position: "absolute",
  top: theme.sizes.x1,
  right: theme.sizes.x1,
});

export default function WorkBlockPreview({ workBlockLayout }) {
  const block = {
    workOrder: {
      itemDescription: "Item description",
      itemCode: "Item code",
      description: "Work order description",
      externalId: "Work order ID",
      customerName: "Customer",
    },
  };

  return (
    <WorkBlockPreviewContainer>
      <BlockCellName>
        <WorkBlockHeader block={block} code="Work order code" workBlockLayout={workBlockLayout} />
      </BlockCellName>
      <BlockCellDetails>
        <Box>Due date</Box>
        <Box>Labor • Quantity expected</Box>
      </BlockCellDetails>
      <BlockMenu icon="more" />
    </WorkBlockPreviewContainer>
  );
}
