import React from "react";
import { Alert, Box, Heading3, Page, Text } from "@nulogy/components";
import omit from "lodash/omit";
import ResetSiteContainer from "./ResetSiteContainer";
import FieldRow from "components/common/FieldRow";
import Checkbox from "components/controls/Checkbox";
import SettingsForm from "components/controls/SettingsForm";
import Input from "components/controls/Input";
import Select from "components/controls/Select";
import TimePicker from "components/controls/TimePicker";
import FormButtons from "components/controls/FormButtons";
import UserIndexView from "components/pages/user/UserIndexView";
import DateTime from "utils/DateTime";
import Time from "utils/Time";
import Codenames from "policies/Codenames";

const formatTime = (time) => DateTime.format(new Date(`2000-01-01 ${time}`), "HH:mm:ss");

const ErrorMessage = ({ error }) => {
  if (!error) return "";

  return (
    <Alert type="danger" marginBottom="16px">
      {error}
    </Alert>
  );
};

export default function SiteEditView({
  companies,
  companyId,
  site,
  timeZones,
  onSave,
  onCancel,
  onDelete,
  canCreateUser,
  canUpdateCompany,
  createUserPath,
  nulogyAdmin,
  error,
  setError,
}) {
  const { id, company, name, users } = site;
  const initialValues = {
    ...omit(site, "settings"),
    startOfDay: Time.to12Hour(site.startOfDay),
    settings: JSON.parse(site.settings),
    company: { id: companyId || company?.id },
    externalId: site.externalId || "",
    externalAccountUuid: site.externalAccountUuid || "",
    seedSampleData: false,
  };

  const companyOptions = companies.map((companyOption) => ({ value: companyOption.id, label: companyOption.name }));

  const handleSave = (values) => {
    const siteValues = omit(values, ["settings", "startOfDay"]);
    const settings = JSON.stringify(values.settings);
    const startOfDay = formatTime(values.startOfDay);

    return onSave({ ...siteValues, settings, startOfDay });
  };

  const timeZoneOptions = timeZones.map((timeZone) => {
    const { displayNameWithOffset, displayName } = timeZone;

    return { label: displayNameWithOffset, value: displayName };
  });

  const title = id ? `Update site - ${name}` : "Create site";

  const validate = (data) => {
    if (!data.startOfDay) {
      setError("Must select start of day");
    } else {
      setError(false);
    }
  };

  return (
    <Page title={title} className="spec-site-create-edit-page">
      <SettingsForm id="site" initialValues={initialValues} onSave={handleSave} validate={validate}>
        <ErrorMessage error={error} />
        {!id || nulogyAdmin ? (
          <FieldRow>
            <Select labelText="Company" options={companyOptions} field="company[id]" className="spec-site-company" />
          </FieldRow>
        ) : (
          <FieldRow>
            <Text fontSize="small" fontWeight="bold">
              Company name
            </Text>
            <Text>{company.name}</Text>
          </FieldRow>
        )}
        <FieldRow>
          <Input data-testid="site-name" field="name" labelText="Name" />
        </FieldRow>
        <FieldRow>
          <Select
            field="timeZoneDisplayName"
            labelText="Time zone"
            className="spec-time-zone"
            options={timeZoneOptions}
          />
        </FieldRow>
        <FieldRow>
          <TimePicker field="startOfDay" labelText="Start of day" className="spec-time-picker" />
        </FieldRow>
        <FieldRow>
          <Input
            data-testid="site-external-id"
            field="externalId"
            labelText={`${Codenames.Packmanager} site UUID`}
            disabled={!nulogyAdmin}
          />
        </FieldRow>
        <FieldRow>
          <Input
            data-testid="external-account-id"
            field="externalAccountUuid"
            labelText={`${Codenames.Packmanager} account UUID`}
            disabled={!nulogyAdmin}
          />
        </FieldRow>
        <Box marginBottom="x2">
          <Heading3>Site settings</Heading3>
          <Checkbox field="settings[showCustomWorkOrderFields]" labelText="Show custom work order fields" />
          <Checkbox field="settings[productLedGrowthCustomer]" labelText="Product led growth customer" />
        </Box>
        <Box marginBottom="x2">
          <Heading3>Dev toggles</Heading3>
          {/* To add a dev toggle:
           <Checkbox field="settings[enableLineOrdering]" labelText="Enable line ordering" />
           */}
          <Checkbox field="settings[newSearchInput]" labelText="New search input" />
        </Box>
        {!id && (
          <Box marginBottom="x2" data-testid="sample-data">
            <Heading3>Sample Data</Heading3>
            <Checkbox field="seedSampleData" labelText="Populate site with sample data" />
          </Box>
        )}
        <FormButtons onCancel={onCancel} onDelete={id ? onDelete : null} disabled={error} />
      </SettingsForm>

      {id && (
        <>
          <hr />
          <Box pt="x2">
            <ResetSiteContainer id={id} />
          </Box>
          <hr />
          <Box pt="x2">
            <UserIndexView
              users={users}
              canCreateUser={canCreateUser}
              canUpdateCompany={canUpdateCompany}
              createUserPath={createUserPath}
            />
          </Box>
        </>
      )}
    </Page>
  );
}
