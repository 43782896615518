import React from "react";
import { Box, Flex } from "@nulogy/components";
import ProductionDetailsSummary from "./ProductionDetailsSummary";
import ProductionDetail from "./ProductionDetail";
import Field from "components/common/Field";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import PackmanagerWorkOrderLink from "components/pages/schedule/PackmanagerWorkOrderLink";
import {
  formatDateTime,
  formatEfficiency,
  formatPerformance,
  formatProductionRate,
  formatQuantity,
  formatTeardownTimeInputInHours,
} from "components/helpers/format";
import Form from "components/controls/Form";
import Section from "components/common/Section";
import LockToggle from "components/controls/LockToggle";
import FormErrors from "components/controls/FormErrors";
import Input from "components/controls/Input";
import FieldRow from "components/common/FieldRow";
import DatePicker from "components/controls/DatePicker";
import Textarea from "components/controls/Textarea";
import CollapsibleSection from "components/common/CollapsibleSection";
import PackmanagerJobLink from "components/pages/schedule/PackmanagerJobLink";
import DateTime from "utils/DateTime";
import Num from "utils/Num";
import WorkBlock from "domain/WorkBlock";

export default function EditWorkBlockModalView(props) {
  const {
    onClose,
    blockData,
    onSave,
    errors,
    setErrors,
    selectedWorkOrder,
    selectedLine,
    effectiveProductionRateFor,
    expectedQuantityFor,
    workBlockLayout,
    workBlockModalUIState,
  } = props;

  const applicationContext = useApplicationContext();
  const { block } = blockData;
  const initialWorkOrder = block.workOrder;
  const initialValues = {
    workBlock: {
      ...block,
      workOrder: {
        id: initialWorkOrder?.id,
        notes: initialWorkOrder?.notes,
        productionRateDependsOnNumberOfPeople: initialWorkOrder?.productionRateDependsOnNumberOfPeople,
      },
      lockedOnSchedule: block.lockedOnSchedule || false,
      teardownTimeDuration: block.teardownTimeDuration,
      teardownTimeDurationHours: formatTeardownTimeInputInHours(block.teardownTimeDuration),
      workTimeStartsAt: block.workTimeStartsAt,
      workTimeEndsAt: block.workTimeEndsAt,
      startsAt: block.startsAt,
      endsAt: block.endsAt,
      productionRateOverride: block.productionRateOverride || "",
      performanceOverride: Num.decimalToPercent(block.performanceOverride) || "",
      efficiencyOverride: Num.decimalToPercent(block.efficiencyOverride) || "",
      laborOverride: block.laborOverride || "",
      jobId: block.jobId,
    },
  };
  const { productionDetailsOpen, toggleProductionDetails } = workBlockModalUIState;

  const validate = (data) => {
    const validationErrors = [];

    if (new Date(data.workBlock.workTimeStartsAt) >= new Date(data.workBlock.workTimeEndsAt)) {
      validationErrors.push("End time must be after start time");
    }

    const {
      workBlock: {
        productionRateOverride: inputProductionRateOverride,
        performanceOverride: inputPerformanceOverride,
        efficiencyOverride: inputEfficiencyOverride,
        laborOverride: inputLaborOverride,
        teardownTimeDurationHours: inputTeardownTimeDurationHours,
      },
    } = data;

    if (
      inputProductionRateOverride !== null &&
      inputProductionRateOverride <= 0 &&
      inputProductionRateOverride !== ""
    ) {
      validationErrors.push("Production rate override must be greater than 0");
    }

    if (inputPerformanceOverride !== null && inputPerformanceOverride <= 0 && inputPerformanceOverride !== "") {
      validationErrors.push("Performance override must be greater than 0");
    }

    if (inputEfficiencyOverride !== null && inputEfficiencyOverride <= 0 && inputEfficiencyOverride !== "") {
      validationErrors.push("Efficiency override must be greater than 0");
    }

    if (inputLaborOverride !== null && inputLaborOverride <= 0 && inputLaborOverride !== "") {
      validationErrors.push("Labor override must be greater than 0");
    }

    if (
      inputTeardownTimeDurationHours !== null &&
      inputTeardownTimeDurationHours < 0 &&
      inputTeardownTimeDurationHours !== ""
    ) {
      validationErrors.push("Teardown time must be greater than or equal to 0");
    }

    setErrors({ base: validationErrors });
  };

  const handleSave = (data) =>
    new Promise(() => {
      onSave({
        ...data.workBlock,
        productionRateOverride: data.workBlock.productionRateOverride || null,
        performanceOverride: Num.percentToDecimal(data.workBlock.performanceOverride) || null,
        efficiencyOverride: Num.percentToDecimal(data.workBlock.efficiencyOverride) || null,
        laborOverride: data.workBlock.laborOverride || null,
        __typename: "WorkBlock",
      });
      onClose();
    });

  const onTeardownTimeChange = ({ value, setFieldValue }) => {
    setFieldValue("workBlock[teardownTimeDuration]", DateTime.hoursToSeconds(value));
  };

  const lineEfficiency = WorkBlock.lineEfficiency({ ...block, line: selectedLine });

  const productionRateDependsOnNumberOfPeople =
    initialValues.workBlock?.productionRateDependsOnNumberOfPeople ||
    selectedWorkOrder?.productionRateDependsOnNumberOfPeople;
  const standardPeople = initialValues.workBlock?.standardPeople || selectedWorkOrder?.standardPeople;
  const formattedStandardPeople = formatQuantity(standardPeople);
  const formattedLineEfficiency = formatEfficiency(lineEfficiency); // TODO: What's the right variable?
  const description = WorkBlock.description({ workOrder: selectedWorkOrder }, workBlockLayout);

  return (
    <Form id="editWorkBlock" validate={validate} initialValues={initialValues} onSave={handleSave}>
      {({ values: { workBlock } }) => (
        <>
          {errors.base.length > 0 && <FormErrors errors={errors} />}
          <Section title="Scheduling details" controls={<LockToggle field="workBlock[lockedOnSchedule]" />}>
            <FieldRow marginBottom={0}>
              <Field data-testid="selected-work-order" labelText="Work order" className="spec-selected-work-order">
                {description && `${description} • `}
                <PackmanagerWorkOrderLink externalUuid={selectedWorkOrder.externalUuid} code={selectedWorkOrder.code} />
              </Field>
            </FieldRow>
            {block.jobId && (
              <FieldRow marginBottom={0} data-testid="published-job">
                <Field labelText="Job">
                  <PackmanagerJobLink jobId={block.jobId} applicationContext={applicationContext} />
                </Field>
              </FieldRow>
            )}
            <FieldRow marginBottom={0}>
              <Field labelText="Line" className="spec-selected-line">
                {selectedLine.name}
              </Field>
            </FieldRow>
            <FieldRow>
              <Flex className="customDatePicker">
                <Box width={1 / 2} marginRight="x3">
                  <DatePicker
                    field="workBlock[workTimeStartsAt]"
                    labelText="Work starts at"
                    className="spec-work-time-starts-at"
                    disabled={workBlock.lockedOnSchedule}
                  />
                </Box>
                <Box width={1 / 2} marginLeft="x3">
                  <DatePicker
                    field="workBlock[workTimeEndsAt]"
                    labelText="Work ends at"
                    className="spec-work-time-ends-at"
                    disabled={workBlock.lockedOnSchedule}
                  />
                </Box>
              </Flex>
            </FieldRow>
          </Section>
          <Section title="Teardown time" className="spec-teardown-time-section">
            <FieldRow>
              <Box marginRight="x3">
                <Input
                  field="workBlock[teardownTimeLabel]"
                  labelText="Teardown label"
                  disabled={workBlock.lockedOnSchedule}
                  id="teardown-time-label"
                />
              </Box>
              <Input
                data-testid="teardown-time-duration"
                field="workBlock[teardownTimeDurationHours]"
                onChange={onTeardownTimeChange}
                labelText="Teardown duration"
                type="number"
                step="any"
                marginLeft="x3"
                inputWidth="100px"
                suffix="hours"
                className="spec-teardown-time-duration"
                disabled={workBlock.lockedOnSchedule}
              />
            </FieldRow>
            {workBlock.teardownTimeDurationHours > 0 && (
              <Flex>
                <Box width={1 / 2} marginRight="x3">
                  <Field labelText="Teardown time starts at">{formatDateTime(workBlock.workTimeEndsAt)}</Field>
                </Box>
                <Box width={1 / 2} marginLeft="x3">
                  <Field labelText="Teardown time ends at">
                    {formatDateTime(
                      DateTime.addDuration(workBlock.workTimeEndsAt, workBlock.teardownTimeDuration, "seconds"),
                    )}
                  </Field>
                </Box>
              </Flex>
            )}
          </Section>
          <CollapsibleSection
            isOpen={productionDetailsOpen}
            onToggle={toggleProductionDetails}
            title="Production details"
          >
            <ProductionDetail
              label="Standard production rate"
              value={formatProductionRate({
                productionRate: workBlock.standardProductionRate,
                unitOfMeasure: selectedWorkOrder.unitOfMeasure,
              })}
              overrideLabel="Production rate override"
              overrideValue={workBlock.productionRateOverride}
              units={`${selectedWorkOrder.unitOfMeasure}/hour`}
              field="productionRateOverride"
              specClassName="spec-base-production-rate"
              disabled={workBlock.lockedOnSchedule}
            />
            <ProductionDetail
              label="Performance"
              value={formatPerformance(workBlock.performance)}
              overrideLabel="Performance override"
              overrideValue={workBlock.performanceOverride}
              units="% performance"
              field="performanceOverride"
              specClassName="spec-performance"
              disabled={workBlock.lockedOnSchedule}
            />
            <ProductionDetail
              data-testid="standard-people-detail"
              label="Standard people"
              value={formattedStandardPeople}
              overrideLabel="Labor override"
              overrideValue={workBlock.laborOverride}
              units="people"
              field="laborOverride"
              // :not_backward_compatible: Remove 'spec-standard-labor' once PS-2994 is deployed.
              // specClassName="spec-standard-people"
              specClassName="spec-standard-people spec-standard-labor"
              disabled={workBlock.lockedOnSchedule}
              additionalInfo={
                productionRateDependsOnNumberOfPeople ? (
                  <span>Changing labor will affect production rate</span>
                ) : (
                  <span>
                    Changing labor will <strong>not</strong> affect production rate
                  </span>
                )
              }
            />
            <ProductionDetail
              label="Efficiency on line"
              value={formattedLineEfficiency}
              overrideLabel="Efficiency override"
              overrideValue={workBlock.efficiencyOverride}
              units="% efficiency"
              field="efficiencyOverride"
              specClassName="spec-line-efficiency"
              disabled={workBlock.lockedOnSchedule}
            />
            <ProductionDetailsSummary
              effectiveProductionRate={effectiveProductionRateFor(workBlock)}
              expectedQuantity={expectedQuantityFor(workBlock)}
              unitOfMeasure={selectedWorkOrder.unitOfMeasure}
            />
          </CollapsibleSection>

          <Section title="Work order notes">
            <Textarea field="workBlock[workOrder][notes]" rows={5} />
          </Section>
        </>
      )}
    </Form>
  );
}
