import React, { useState } from "react";
import { useField } from "formik";
import styled from "styled-components";
import { Box, Flex, Heading2, Heading4, Text, Tooltip, theme } from "@nulogy/components";
import Checkbox from "components/controls/Checkbox";
import WorkBlockPreview from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkBlockPreview";
import LabelWithTooltip from "components/common/LabelWithTooltip";
import Radio from "components/controls/Radio";
import WorkBlock from "domain/WorkBlock";
import WorkOrderCard from "components/pages/schedule/workOrderCards/WorkOrderCard";
import Uuid from "utils/Uuid";
import DateTime from "utils/DateTime";

const SectionHeader = styled(Heading2)`
  font-weight: ${theme.fontWeights.medium};
`;

const SubHeader = styled(Text)`
  margin-top: ${theme.space.x3};
  margin-bottom: ${theme.space.half};
`;

const Columns = styled(Box)`
  label {
    padding: ${theme.space.half} 0;
  }
`;

const CustomRadio = styled(Radio)`
  label {
    padding: ${theme.space.half} 0;
  }
`;

const LayoutHeading = styled(Heading4)`
  margin-bottom: ${theme.space.half};
  font-weight: ${theme.fontWeights.medium};
`;

function KeepTooltip() {
  return (
    <Tooltip
      tooltip="If selected, this field will appear on the work order card when expanded or collapsed."
      placement="right"
      maxWidth="220px"
    >
      <img
        width={theme.sizes.x2}
        height={theme.sizes.x2}
        src="./svg/components/pages/settings/keep.svg"
        alt="If selected, this field will appear on the work order card when expanded or collapsed."
      />
    </Tooltip>
  );
}

const futureDate = DateTime.addDuration(DateTime.now(), 3, "months");
const startOfWorkOrder = DateTime.startOfMonth(futureDate);
const endOfWorkOrder = DateTime.endOfMonth(futureDate);

const sampleWorkOrder = {
  code: "Work order code",
  completed: false,
  customerName: "Customer",
  customWorkOrderFieldColor: "orange",
  customWorkOrderFieldName: "Custom value",
  description: "Production of sample item 123",
  due: endOfWorkOrder,
  endAt: endOfWorkOrder,
  externalId: "0000123",
  externalUuid: Uuid.generate(),
  id: Uuid.generate(),
  itemCategory: {
    name: "Category Y",
  },
  itemClass: {
    name: "Class A",
  },
  itemCode: "ITEM-123",
  itemDescription: "Sample item 123",
  itemFamily: {
    name: "Family 1",
  },
  itemType: {
    name: "Type X",
  },
  materialAvailabilityDate: startOfWorkOrder,
  quantity: 20000,
  producedQuantity: 12000,
  reference1: "123",
  reference2: "456",
  reference3: "789",
  remainingDuration: 6 * 60 * 60,
  schedulable: false,
  scheduledDuration: 14 * 60 * 60,
  scheduledQuantity: 14000,
  serviceCategory: {
    name: "Service category Z",
  },
  standardPeople: 10,
  standardProductionRate: 1000,
  startAt: startOfWorkOrder,
  status: "open",
  unitOfMeasure: "cases",
  unschedulable: false,
  unscheduledQuantity: 6000,
};

export default function LayoutSetting() {
  const WorkBlockLayout = () =>
    WorkBlock.LAYOUTS.map((layout) => {
      const blockPreview = <WorkBlockPreview workBlockLayout={layout.value} />;
      const labelComponent = <LabelWithTooltip label={layout.label} tooltip={blockPreview} tooltipPlacement="right" />;

      return (
        <CustomRadio
          key={`work-block-layout-${layout.value}`}
          labelText={labelComponent}
          field="workBlockLayout"
          value={layout.value}
          tooltip={blockPreview}
          tooltipPlacement="right"
          className={`spec-work-block-layout-${layout.value}`}
        />
      );
    });

  const [workOrderCardExpanded, setWorkOrderCardExpanded] = useState(true);

  const [workOrderCardLayoutField] = useField("workOrderCardLayout");

  return (
    <Flex flexDirection="column" gap="x4" mb="x2">
      <Box>Customize the layout of work blocks and work order cards on your production schedule.</Box>
      <Box data-testid="work-block-layout-settings">
        <SectionHeader>Work blocks</SectionHeader>
        <SubHeader>Select what should be displayed on the first line of a work block.</SubHeader>
        <WorkBlockLayout />
      </Box>
      <Box>
        <SectionHeader>Work order cards</SectionHeader>
        <SubHeader>Select the fields that will appear on a work order card.</SubHeader>
        <Flex gap="x8" mt="x2" data-testid="work-order-card-layout-setting">
          <Box>
            <Columns>
              <LayoutHeading>Work order fields</LayoutHeading>
              <Checkbox
                field="workOrderCardLayout[workOrderId]"
                labelText="Work order ID"
                className="spec-work-order-layout-field-work-order-id"
              />
              <Checkbox
                field="workOrderCardLayout[workOrderDescription]"
                labelText="Work order description"
                className="spec-work-order-layout-field-work-order-description"
              />
              <Checkbox
                field="workOrderCardLayout[materialAvailabilityDate]"
                labelText="Material availability date"
                className="spec-work-order-layout-field-material-availability-date"
              />
              <Checkbox
                field="workOrderCardLayout[plannedStartDate]"
                labelText="Planned start (from Shop Floor)"
                className="spec-work-order-layout-field-planned-start-date"
              />
              <Checkbox
                field="workOrderCardLayout[plannedEndDate]"
                labelText="Planned end (from Shop Floor)"
                className="spec-work-order-layout-field-planned-end-date"
              />
              <Checkbox
                field="workOrderCardLayout[customWorkOrderField]"
                labelText="Custom work order field"
                className="spec-work-order-layout-field-custom-work-order-field"
              />
              <Checkbox
                field="workOrderCardLayout[reference1]"
                labelText="Reference 1"
                className="spec-work-order-layout-field-reference-1"
              />
              <Checkbox
                field="workOrderCardLayout[reference2]"
                labelText="Reference 2"
                className="spec-work-order-layout-field-reference-2"
              />
              <Checkbox
                field="workOrderCardLayout[reference3]"
                labelText="Reference 3"
                className="spec-work-order-layout-field-reference-3"
              />
            </Columns>
          </Box>
          <Box>
            <Columns>
              <LayoutHeading>Item fields</LayoutHeading>
              <Flex gap="half">
                <Checkbox
                  field="workOrderCardLayout[itemCode]"
                  labelText="Item code"
                  className="spec-work-order-layout-field-item-code"
                />
                <KeepTooltip />
              </Flex>
              <Flex gap="half">
                <Checkbox
                  field="workOrderCardLayout[itemDescription]"
                  labelText="Item description"
                  className="spec-work-order-layout-field-item-description"
                />
                <KeepTooltip />
              </Flex>
              <Checkbox
                field="workOrderCardLayout[itemClass]"
                labelText="Item class"
                className="spec-work-order-layout-field-item-class"
              />
              <Checkbox
                field="workOrderCardLayout[itemFamily]"
                labelText="Item family"
                className="spec-work-order-layout-field-item-family"
              />
              <Checkbox
                field="workOrderCardLayout[itemType]"
                labelText="Item type"
                className="spec-work-order-layout-field-item-type"
              />
              <Checkbox
                field="workOrderCardLayout[itemCategory]"
                labelText="Item category"
                className="spec-work-order-layout-field-item-category"
              />
              <Checkbox
                field="workOrderCardLayout[serviceCategory]"
                labelText="Service category"
                className="spec-work-order-layout-field-service-category"
              />
              <Checkbox
                field="workOrderCardLayout[standardProductionRate]"
                labelText="Standard production rate"
                className="spec-work-order-layout-field-standard-production-rate"
              />
              <Checkbox
                field="workOrderCardLayout[standardPeople]"
                labelText="Standard people"
                className="spec-work-order-layout-field-standard-people"
              />
            </Columns>
          </Box>
          <Box ml="x4">
            <WorkOrderCard
              workOrder={sampleWorkOrder}
              expanded={workOrderCardExpanded}
              onToggleExpandWorkOrder={() => {
                setWorkOrderCardExpanded(!workOrderCardExpanded);
              }}
              layoutSettingData={{ workOrderLayoutSetting: workOrderCardLayoutField.value }}
              showOptions={false}
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
