import React from "react";
import { Modal, NDSProvider } from "@nulogy/components";
import styled, { createGlobalStyle } from "styled-components";
import Router from "./Router";
import ApplicationLayoutContainer from "./layouts/ApplicationLayoutContainer";
import GqlProvider from "./GqlProvider";
import ErrorBoundary from "./ErrorBoundary";
import NotificationContainer from "./common/NotificationContainer";
import Pendo from "./common/Pendo";
import { GlobalStateProvider } from "contexts/GlobalStateContext";
import { WorkOrderSearchProvider } from "contexts/WorkOrderSearchContext";
import { UIStateProvider } from "contexts/UIStateContext";
import { ConfirmModalContextProvider } from "contexts/ConfirmModalContext";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import ApplicationContext from "domain/ApplicationContext";
import { NetworkStateProvider } from "contexts/NetworkStateContext";

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    overscroll-behavior-x: none;
  }

  a {
    text-decoration: none;
  }
`;

const BranchDisplay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 12px;
  color: blue;
  padding: 4px;
`;

function Branch() {
  const applicationContext = useApplicationContext();
  if (applicationContext.loading) return null;
  if (!applicationContext.branch || applicationContext.branch === "main") return null;

  return <BranchDisplay>{applicationContext.branch}</BranchDisplay>;
}

function StateProviders({ children }) {
  const applicationContext = useApplicationContext();
  if (applicationContext.loading) return null;

  const sessionIdentifier = ApplicationContext.sessionIdentifier(applicationContext);

  return (
    <GlobalStateProvider>
      <UIStateProvider sessionIdentifier={sessionIdentifier}>
        <ConfirmModalContextProvider>
          <NetworkStateProvider>
            <WorkOrderSearchProvider sessionIdentifier={sessionIdentifier}>{children}</WorkOrderSearchProvider>
          </NetworkStateProvider>
        </ConfirmModalContextProvider>
      </UIStateProvider>
    </GlobalStateProvider>
  );
}

const App = (
  <ErrorBoundary>
    <GqlProvider>
      <NDSProvider>
        <StateProviders>
          <GlobalStyle />
          <Pendo>
            <ApplicationLayoutContainer>
              <NotificationContainer />
              <Router />
              <Branch />
            </ApplicationLayoutContainer>
          </Pendo>
        </StateProviders>
      </NDSProvider>
    </GqlProvider>
  </ErrorBoundary>
);

Modal.setAppElement(document.getElementById("root"));

export default App;
