import styled from "styled-components";

const BlockCellName = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  a {
    font-weight: 700;
  }
`;

export default BlockCellName;
