import React from "react";
import styled from "styled-components";
import { Box, DatePicker, Flex, Input, Toggle } from "@nulogy/components";
import FilterLabel from "./FilterLabel";
import CustomerSelector from "./CustomerSelector";
import CustomWorkOrderFieldSelector from "./CustomWorkOrderFieldSelector";
import StatusSelector from "./StatusSelector";
import LabelWithTooltip from "components/common/LabelWithTooltip";

const DateRangeSeparator = styled.div`
  padding: 7px;
  text-align: center;
`;

const showCompletedLabel = (
  <LabelWithTooltip
    label="Show scheduled, completed, cancelled"
    tooltip="Only underscheduled work orders are shown by default. Scheduled, completed, and cancelled work orders are hidden."
  />
);

const filterByScheduleViewLabel = (
  <LabelWithTooltip
    label="Filter by current schedule view"
    tooltip="Shows work orders that are within your current view of the schedule."
  />
);

const showMissingDataLabel = (
  <LabelWithTooltip
    label="Show work orders with errors"
    tooltip="Work orders with invalid data for the production rate or work order quantity fields cannot be scheduled."
  />
);

export default function FilterSidebarView({
  currentFilter,
  onChangeToggleFilter,
  onChangeFilter,
  onChangeSelect,
  applicationContext,
  onTextSearch,
  onToggleFilterByScheduleView,
}) {
  const {
    settings: { showCustomWorkOrderFields },
  } = applicationContext;
  const scheduledBetweenDisabled = currentFilter.filterByScheduleView;

  return (
    <Box marginBottom="20px">
      <Flex alignItems="center">
        <Toggle
          onClick={() => onChangeToggleFilter("showScheduledCompletedOrCancelled")}
          className="spec-toggle-scheduled"
          onText={showCompletedLabel}
          offText={showCompletedLabel}
          toggled={currentFilter.showScheduledCompletedOrCancelled}
        />
      </Flex>
      <Flex alignItems="center">
        <Toggle
          onClick={() => onChangeToggleFilter("showMissingData")}
          className="spec-toggle-missing-data"
          onText={showMissingDataLabel}
          offText={showMissingDataLabel}
          toggled={currentFilter.showMissingData}
        />
      </Flex>
      <Flex alignItems="center">
        <Toggle
          onClick={onToggleFilterByScheduleView}
          className="spec-toggle-filter-by-schedule-view"
          onText={filterByScheduleViewLabel}
          offText={filterByScheduleViewLabel}
          toggled={currentFilter.filterByScheduleView}
        />
      </Flex>
      <FilterLabel marginTop="12px" value="Scheduled" />
      <Flex data-testid="scheduled-between-date-picker" className="customDatePicker">
        <Box width={45 / 100}>
          <DatePicker
            className="spec-scheduled-between-starts-at"
            selected={currentFilter.scheduledBetween.startsAt}
            dateFormat="MMM d, yyyy"
            popperProps={{ strategy: "fixed" }}
            onChange={(date) => {
              onChangeFilter({ scheduledBetween: { startsAt: date } });
            }}
            inputProps={{ placeholder: scheduledBetweenDisabled ? " " : "From", disabled: scheduledBetweenDisabled }}
          />
        </Box>
        <Box width={10 / 100}>
          <DateRangeSeparator>&mdash;</DateRangeSeparator>
        </Box>
        <Box width={45 / 100}>
          <DatePicker
            disabled={currentFilter.filterByScheduleView}
            className="spec-scheduled-between-ends-at"
            selected={currentFilter.scheduledBetween.endsAt}
            dateFormat="MMM d, yyyy"
            popperProps={{ strategy: "fixed" }}
            onChange={(date) => {
              onChangeFilter({ scheduledBetween: { endsAt: date } });
            }}
            inputProps={{ placeholder: scheduledBetweenDisabled ? " " : "To", disabled: scheduledBetweenDisabled }}
          />
        </Box>
      </Flex>
      <FilterLabel value="Due date" />
      <Flex className="customDatePicker">
        <Box width={45 / 100}>
          <DatePicker
            className="spec-due-date-starts-at"
            selected={currentFilter.dueBetween.startsAt}
            dateFormat="MMM d, yyyy"
            popperProps={{ strategy: "fixed" }}
            onChange={(date) => {
              onChangeFilter({ dueBetween: { startsAt: date } });
            }}
            inputProps={{ placeholder: "From" }}
          />
        </Box>
        <Box width={10 / 100}>
          <DateRangeSeparator>&mdash;</DateRangeSeparator>
        </Box>
        <Box width={45 / 100}>
          <DatePicker
            className="spec-due-date-ends-at"
            selected={currentFilter.dueBetween.endsAt}
            dateFormat="MMM d, yyyy"
            popperProps={{ strategy: "fixed" }}
            onChange={(date) => {
              onChangeFilter({ dueBetween: { endsAt: date } });
            }}
            inputProps={{ placeholder: "To" }}
          />
        </Box>
      </Flex>
      <FilterLabel value="Material availability date" />
      <Flex className="customDatePicker">
        <Box width={45 / 100}>
          <DatePicker
            className="spec-material-availability-date-starts-at"
            selected={currentFilter.materialAvailabilityBetween.startsAt}
            dateFormat="MMM d, yyyy"
            popperProps={{ strategy: "fixed" }}
            onChange={(date) => {
              onChangeFilter({ materialAvailabilityBetween: { startsAt: date } });
            }}
            inputProps={{ placeholder: "From" }}
          />
        </Box>
        <Box width={10 / 100}>
          <DateRangeSeparator>&mdash;</DateRangeSeparator>
        </Box>
        <Box width={45 / 100}>
          <DatePicker
            className="spec-material-availability-date-ends-at"
            selected={currentFilter.materialAvailabilityBetween.endsAt}
            dateFormat="MMM d, yyyy"
            popperProps={{ strategy: "fixed" }}
            onChange={(date) => {
              onChangeFilter({ materialAvailabilityBetween: { endsAt: date } });
            }}
            inputProps={{ placeholder: "To" }}
          />
        </Box>
      </Flex>
      <FilterLabel value="Planned start date (from Shop Floor)" />
      <Flex className="customDatePicker">
        <Box width={45 / 100}>
          <DatePicker
            className="spec-planned-start-date-starts-at"
            selected={currentFilter.plannedStartBetween.startsAt}
            dateFormat="MMM d, yyyy"
            popperProps={{ strategy: "fixed" }}
            onChange={(date) => {
              onChangeFilter({ plannedStartBetween: { startsAt: date } });
            }}
            inputProps={{ placeholder: "From" }}
          />
        </Box>
        <Box width={10 / 100}>
          <DateRangeSeparator>&mdash;</DateRangeSeparator>
        </Box>
        <Box width={45 / 100}>
          <DatePicker
            className="spec-planned-start-date-ends-at"
            selected={currentFilter.plannedStartBetween.endsAt}
            dateFormat="MMM d, yyyy"
            popperProps={{ strategy: "fixed" }}
            onChange={(date) => {
              onChangeFilter({ plannedStartBetween: { endsAt: date } });
            }}
            inputProps={{ placeholder: "To" }}
          />
        </Box>
      </Flex>
      <FilterLabel value="Work order code" />
      <Input
        name="workOrderCode"
        defaultValue={currentFilter.workOrderCode}
        className="searchFilterInput"
        onChange={(event) => {
          onTextSearch({ workOrderCode: event?.target?.value });
        }}
      />
      <FilterLabel value="Work order ID" />
      <Input
        name="externalId"
        defaultValue={currentFilter.externalId}
        className="searchFilterInput"
        onChange={(event) => {
          onTextSearch({ externalId: event?.target?.value });
        }}
      />
      <StatusSelector selectedStatuses={currentFilter.statuses} onChange={onChangeSelect("statuses")} />

      {showCustomWorkOrderFields && (
        <CustomWorkOrderFieldSelector
          selectedCustomWorkOrderFields={currentFilter.customWorkOrderFields}
          onChange={onChangeSelect("customWorkOrderFields")}
          applicationContext={applicationContext}
        />
      )}
      <FilterLabel value="Item code" />
      <Input
        name="itemCode"
        defaultValue={currentFilter.itemCode}
        className="searchFilterInput"
        onChange={(event) => {
          onTextSearch({ itemCode: event?.target?.value });
        }}
      />
      <FilterLabel value="Item description" />
      <Input
        name="itemDescription"
        defaultValue={currentFilter.itemDescription}
        className="searchFilterInput"
        onChange={(event) => {
          onTextSearch({ itemDescription: event?.target?.value });
        }}
      />
      <CustomerSelector selectedCustomers={currentFilter.customers} onChange={onChangeSelect("customers")} />
    </Box>
  );
}
