import React from "react";
import styled from "styled-components";
import { theme } from "@nulogy/components";
import { formatQuantity } from "components/helpers/format";

const ProgressBar = styled.div`
  height: 6px;
  grid-column: 2 / 2;
  background-color: ${theme.colors.lightGrey};
  border-radius: 3px;
  margin: 4px 0;
`;

const Progress = styled.span`
  display: block;
  width: ${({ producedProgressBarWidth }) => `${producedProgressBarWidth}%`};
  height: 100%;
  border-radius: 3px;
  background-color: ${({ producedProgressBarColor }) => producedProgressBarColor};
`;

function calculateProgressBarWidth(workOrder) {
  const { producedQuantity, quantity } = workOrder;

  if (!producedQuantity || !quantity) return 0;

  const roundedProducedQuantity = formatQuantity(producedQuantity);
  const roundedQuantity = formatQuantity(quantity);
  const producedPercentage = Math.floor((roundedProducedQuantity / roundedQuantity) * 100);

  return Math.min(producedPercentage, 100);
}

function calculateProgressBarColor(workOrder, producedProgressBarWidth) {
  const { missingData } = workOrder;

  if (producedProgressBarWidth >= 100) return theme.colors.green;
  if (missingData) return theme.colors.midGrey;

  return theme.colors.blue;
}

export default function ProductionProgressBar({ workOrder }) {
  const producedProgressBarWidth = calculateProgressBarWidth(workOrder);
  const producedProgressBarColor = calculateProgressBarColor(workOrder, producedProgressBarWidth);

  return (
    <ProgressBar>
      <Progress
        data-testid="progress"
        producedProgressBarWidth={producedProgressBarWidth}
        producedProgressBarColor={producedProgressBarColor}
      />
    </ProgressBar>
  );
}
