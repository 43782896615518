import React from "react";
import styled from "styled-components";
import { Select } from "@nulogy/components";
import SearchInput from "components/controls/SearchInput";

const SearchCategorySelect = styled(Select)`
  .ndsSelect__control {
    border-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  input {
    border-top: 0 !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
`;

export default function WorkOrderSearch({
  searchCategoryOptions,
  searchCategory,
  searchText,
  onTextSearch,
  onCategoryChange,
}) {
  const handleWorkOrderSearch = (event) => {
    const newSearchText = event?.target?.value;
    if (newSearchText === searchText) return;

    onTextSearch({ searchText: newSearchText, searchCategory });
  };

  const handleSearchCategory = (newSearchCategory) => {
    if (newSearchCategory === searchCategory) return;

    onCategoryChange({ searchText, searchCategory: newSearchCategory });
  };

  return (
    <>
      <SearchCategorySelect
        defaultValue=""
        options={searchCategoryOptions}
        value={searchCategory}
        onChange={handleSearchCategory}
      />
      <StyledSearchInput
        mb="x1"
        defaultValue={searchText}
        onChange={handleWorkOrderSearch}
        className="spec-filter-field searchFilterInput pendo-search-filter"
        placeholder="Search all work orders..."
      />
    </>
  );
}
