import React, { useState } from "react";
import { Alert, Box, Flex, InlineValidation, PrimaryButton, Select, Text, theme } from "@nulogy/components";
import classNames from "classnames";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import styled from "styled-components";
import FilterLabel from "../FilterLabel";
import ExpandFiltersButton from "./ExpandFiltersButton";
import JobCount from "./JobCount";
import ApolloLoadingAnimation from "components/common/ApolloLoadingAnimation";
import Form from "components/controls/Form";
import ModalFooter from "components/common/ModalFooter";
import DatePicker from "components/controls/DatePicker";

const FORM_ID = "publish-modal";
const MAX_JOB_COUNT = 1000;

const DROPDOWN_ROW_HEIGHT = 38; // px

export { MAX_JOB_COUNT };

const OptionalNotice = styled(Text)({
  color: theme.colors.darkGrey,
  display: "inline",
  fontSize: theme.fontSizes.smaller,
  fontWeight: theme.fontWeights.normal,
});

const DateRangeSeparator = styled.div`
  padding: 7px;
  text-align: center;
`;
function formErrors(response) {
  const configurationErrors = response.errors.filter((error) => error.path.length !== 3);

  return configurationErrors.length ? configurationErrors : null;
}

export default function PublishScheduleFormView({ onSave, response, ...contentProps }) {
  return (
    <Form id={FORM_ID} onSave={onSave} errors={formErrors(response)}>
      {({ isSubmitting, values }) => <Content isSubmitting={isSubmitting} values={values} {...contentProps} />}
    </Form>
  );
}

function nothingToPublishMessage({ jobCounts: { total }, searchingByLine }) {
  if (total) return null;

  return searchingByLine
    ? "There are no scheduled blocks within the specified filters."
    : "There are no scheduled blocks within the specified date range.";
}

function jobsCreatedAndUpdatedMessage({ jobCounts: { toCreate, toUpdate } }) {
  if (!toCreate && !toUpdate) return null;

  return (
    <>
      <Box
        fontSize={theme.fontSizes.smaller}
        lineHeight={theme.lineHeights.smallerText}
        marginBottom={theme.sizes.half}
      >
        Jobs to be created: <JobCount>{toCreate}</JobCount>
      </Box>
      <Box fontSize={theme.fontSizes.smaller} lineHeight={theme.lineHeights.smallerText}>
        Jobs to be updated: <JobCount>{toUpdate}</JobCount>
      </Box>
    </>
  );
}

export function Content({
  isSubmitting,
  onFilterChange,
  showJobCount,
  filters,
  lines,
  jobCounts,
  schedulePublishing,
  values,
}) {
  const [expandedFilters, setExpandedFilters] = useState(false);
  const { dateRange, linesToPublish, lineTypesToPublish } = filters;

  const jobLimitExceeded = jobCounts.total > MAX_JOB_COUNT;
  const jobsInRange = jobCounts.total > 0 && !jobLimitExceeded;
  const hasDateRange = dateRange.startDate && dateRange.endDate;
  const submitEnabled = jobsInRange && hasDateRange && !schedulePublishing;

  const linesOptions = lines.map((line) => ({ label: line.name, value: line.id }));
  const lineTypesOptions = sortBy(
    uniqBy(
      lines.map((line) => ({ label: line.lineType, value: line.lineType })),
      "value",
    ),
    "label",
  );

  const searchingByLine = linesToPublish.length > 0 || lineTypesToPublish.length > 0;

  const handleDateRangeChange = ({ value, fieldName }) => {
    onFilterChange({ dateRange: { ...values.dateRange, [fieldName]: value } });
  };

  const handleLinesToPublishChange = (updatedLines) => {
    onFilterChange({ linesToPublish: updatedLines });
  };

  const handleLineTypesToPublishChange = (updatedLineTypes) => {
    onFilterChange({ lineTypesToPublish: updatedLineTypes });
  };

  const toggleExpandedFilters = () => {
    setExpandedFilters(!expandedFilters);
  };

  const dateRangeError = values.dateRange?.startDate > values.dateRange?.endDate;
  const datePickerClassNames = classNames("customDatePicker", { error: dateRangeError });

  return (
    <>
      <ApolloLoadingAnimation fetching={schedulePublishing} />
      <Text>
        Publishing a block will create a corresponding job under its work order. If a block was previously published,
        then updates will be reflected in its existing job.
      </Text>
      <Flex className={datePickerClassNames} pt="x1" pb="x1" alignItems="flex-end">
        <Box width={45 / 100}>
          <DatePicker
            field="dateRange[startDate]"
            onChange={({ value }) => handleDateRangeChange({ value, fieldName: "startDate" })}
            placeholderText="Start date, time"
            dateFormat="MMM d, yyyy h:mm a"
          />
        </Box>
        <Box width={10 / 100}>
          <DateRangeSeparator>&mdash;</DateRangeSeparator>
        </Box>
        <Box width={45 / 100}>
          <DatePicker
            field="dateRange[endDate]"
            onChange={({ value }) => handleDateRangeChange({ value, fieldName: "endDate" })}
            placeholderText="End date, time"
            dateFormat="MMM d, yyyy h:mm a"
          />
        </Box>
      </Flex>
      {dateRangeError && <InlineValidation errorMessage="The end date must be after the start date." mt="x1" />}
      {expandedFilters && (
        <>
          <Box data-testid="line-select">
            <FilterLabel
              value={
                <>
                  Line(s) <OptionalNotice>(Optional)</OptionalNotice>
                </>
              }
              marginTop="x1"
              marginBottom={0}
            />
            <Select
              value={linesToPublish}
              options={linesOptions}
              multiselect
              onChange={handleLinesToPublishChange}
              placeholder="Select lines..."
              closeMenuOnSelect={false}
              marginTop="x1"
              maxHeight={5 * DROPDOWN_ROW_HEIGHT}
            />
          </Box>
          <Box data-testid="line-types-select" paddingBottom="x1">
            <FilterLabel
              value={
                <>
                  Line type(s) <OptionalNotice>(Optional)</OptionalNotice>
                </>
              }
              marginTop="x2"
              marginBottom={0}
            />
            <Select
              value={lineTypesToPublish}
              options={lineTypesOptions}
              multiselect
              onChange={handleLineTypesToPublishChange}
              placeholder="Select line types..."
              closeMenuOnSelect={false}
              marginTop="x1"
              maxHeight={3 * DROPDOWN_ROW_HEIGHT}
            />
          </Box>
        </>
      )}
      <ExpandFiltersButton expandedFilters={expandedFilters} onClick={toggleExpandedFilters} />
      {jobCounts.onInactiveLines > 0 && (
        <Alert type="warning" style={{ marginBottom: "16px" }}>
          This date range contains blocks scheduled on inactive lines. Corresponding jobs will be created for them,
          unless they are unscheduled.
        </Alert>
      )}
      <ModalFooter justifyContent="flex-start" alignItems="center">
        <Box marginRight="x2">
          <PrimaryButton type="submit" form={FORM_ID} disabled={!submitEnabled} className="pendo-create-jobs">
            Publish
          </PrimaryButton>
        </Box>
        {!isSubmitting && showJobCount && (
          <Text data-testid="job-count-message" className="spec-publish-modal-job-count-message">
            {nothingToPublishMessage({ jobCounts, searchingByLine })}
            {jobsCreatedAndUpdatedMessage({ jobCounts })}
            {jobLimitExceeded && (
              <Text inline color="red" data-testid="publish-job-limit-warning">
                Only {MAX_JOB_COUNT} jobs can be published at a time. Please reduce the selected date range and try
                again.
              </Text>
            )}
          </Text>
        )}
      </ModalFooter>
    </>
  );
}
