import React from "react";
import styled from "styled-components";
import { Box, Flex, IconicButton, Sidebar } from "@nulogy/components";
import { useQuery } from "@apollo/client";
import SidebarExpander from "./SidebarExpander";
import VirtualizedWorkOrderList from "./VirtualizedWorkOrderList";
import { GetLayoutSetting } from "hooks/apollo/customerSettings/customerSettings.gql";
import ApolloLoadingAnimation from "components/common/ApolloLoadingAnimation";
import ReactExt from "utils/ReactExt";
import { getExpandedFieldCount } from "components/pages/schedule/helpers/workOrderCardHelper";
import WorkOrderSearchNew from "components/pages/schedule/WorkOrderSearch";
import WorkOrderSearchOld from "components/pages/schedule/WorkOrderSearchOld";

export const expandedSidebarWidth = "408px";

const EditFiltersButton = styled(IconicButton)`
  vertical-align: bottom;
  padding: 0;
`;

function SidebarView({
  applicationContext,
  workOrders,
  onTextSearch,
  onCategoryChange,
  numberOfFiltersApplied,
  slideOutState,
  onToggleSidebar,
  sidebarOpen,
  searchText,
  searchCategory,
  fetching,
  searchCategoryOptions,
}) {
  const [expandedWorkOrders, setExpandedWorkOrders] = React.useState([]);
  const { data: layoutSettingData, loading: layoutSettingLoading } = useQuery(GetLayoutSetting);
  const ListRef = React.createRef();

  if (layoutSettingLoading) return null;

  const {
    settings: { newSearchInput },
  } = applicationContext;

  const refreshList = () => {
    if (ListRef.current) {
      ListRef.current.recomputeRowHeights();
      ListRef.current.forceUpdate();
    }
  };

  const onToggleExpandWorkOrder = (workOrderId) => {
    if (expandedWorkOrders.includes(workOrderId)) {
      setExpandedWorkOrders(expandedWorkOrders.filter((id) => id !== workOrderId));
    } else {
      setExpandedWorkOrders([...expandedWorkOrders, workOrderId]);
    }

    refreshList();
  };

  const onExpandAll = () => {
    setExpandedWorkOrders(workOrders.map((workOrder) => workOrder.id));
    refreshList();
  };

  const onCollapseAll = () => {
    setExpandedWorkOrders([]);
    refreshList();
  };

  const handleClickEditFilters = () => {
    if (slideOutState.isFilterMode()) {
      slideOutState.closeSlideOut();
    } else {
      slideOutState.openFilterSidebar();
    }
  };

  const WorkOrderSearch = newSearchInput ? WorkOrderSearchNew : WorkOrderSearchOld;

  const filterLabel = `Filters ${numberOfFiltersApplied > 0 ? `(${numberOfFiltersApplied})` : ""}`;

  const expandedFieldCount = getExpandedFieldCount(layoutSettingData?.workOrderLayoutSetting || []);

  return (
    <>
      <Sidebar
        isOpen={sidebarOpen}
        height="100%"
        onClose={onToggleSidebar}
        closeOnOutsideClick={false}
        overlay={false}
        closeButtonTestId="spec-close-sidebar"
        style={{ zIndex: 10 }}
        title="Work orders"
        width={expandedSidebarWidth}
      >
        <Box ml="-8px" width="calc(100% + 16px)">
          <WorkOrderSearch
            searchCategoryOptions={searchCategoryOptions}
            searchCategory={searchCategory}
            searchText={searchText}
            onTextSearch={onTextSearch}
            onCategoryChange={onCategoryChange}
          />
          <Flex justifyContent="space-between">
            <EditFiltersButton
              size="small"
              icon="filter"
              onClick={handleClickEditFilters}
              data-testid="filters-applied"
              className="spec-filters-applied"
            >
              {filterLabel}
            </EditFiltersButton>
            {expandedFieldCount > 0 && (
              <Flex zIndex={5} gap="2px" mr="half">
                <IconicButton p={0} icon="expand" tooltip="Expand all" onClick={onExpandAll} />
                <IconicButton p={0} icon="collapse" tooltip="Collapse all" onClick={onCollapseAll} />
              </Flex>
            )}
          </Flex>
        </Box>
        <VirtualizedWorkOrderList
          workOrders={workOrders}
          onToggleExpandWorkOrder={onToggleExpandWorkOrder}
          expandedWorkOrders={expandedWorkOrders}
          ListRef={ListRef}
          layoutSettingData={layoutSettingData}
        />
        <ApolloLoadingAnimation fetching={fetching} />
      </Sidebar>
      {!sidebarOpen && <SidebarExpander onToggleSidebar={onToggleSidebar} />}
    </>
  );
}

export default React.memo(SidebarView, ReactExt.checkPropsForReRender);
