import { theme } from "@nulogy/components";
import styled from "styled-components";

const teardownGridColumns = ({ teardownWidth }) => {
  if (teardownWidth > 0) {
    return `1fr minmax(0, 20px) ${teardownWidth}px`;
  } else {
    return "1fr minmax(0, 20px)";
  }
};

const BlockContainer = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: ${teardownGridColumns};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-weight: ${theme.fontWeights.normal};
  padding-left: 0;
  ${({ readonly }) => readonly && "cursor: default"}
`;

export default BlockContainer;
