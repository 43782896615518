import { useContext } from "react";
import useBryntumScheduler from "hooks/useBryntumScheduler";
import UIStateContext from "contexts/UIStateContext";

const WORK_BLOCK_HIGHLIGHT_DURATION = 1750;
export default function useHighlightWorkBlock() {
  const { getBryntumScheduler } = useBryntumScheduler();
  const { getState, setState } = useContext(UIStateContext);

  return {
    highlightWorkBlock: () => {
      setTimeout(() => {
        const scheduler = getBryntumScheduler();
        const { selectedWorkBlock } = getState();

        if (!scheduler || !selectedWorkBlock) return;

        const id = selectedWorkBlock?.id;
        const workBlock = scheduler.eventStore?.query((record) => record?.block?.id === id)[0];
        if (!workBlock) return;
        const element = document.querySelector(`.work-block-${workBlock.id}`);
        const allBlocks = document.querySelectorAll(".b-sch-event");
        if (element) {
          element.classList.add("b-sch-event-focused");
          allBlocks.forEach((e) => {
            if (e !== element) e.classList.add("b-sch-event-faded");
          });

          setTimeout(() => {
            element.classList.remove("b-sch-event-focused");
            allBlocks.forEach((e) => e.classList.remove("b-sch-event-faded"));
          }, WORK_BLOCK_HIGHLIGHT_DURATION);

          const currentUIState = getState();

          setState({
            ...currentUIState,
            selectedWorkBlock: {
              id: null,
              selectedAt: null,
            },
          });
        }
      }, 1000);
    },
  };
}
