import styled from "styled-components";
import { Text, theme } from "@nulogy/components";

const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: ${({ lineHeight }) => lineHeight || theme.lineHeights.smallerText};
`;

export default TruncatedText;
