import styled from "styled-components";

const BlockCellDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 12px;
  top: -4px;
  line-height: 16px;
`;

export default BlockCellDetails;
