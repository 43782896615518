import omit from "lodash/omit";

function getAlwaysVisibleFieldCount(workOrderLayoutSettings) {
  const { itemCode, itemDescription } = workOrderLayoutSettings;
  const productionFields = 3;
  const alwaysVisibleFields = 1;

  return [itemCode, itemDescription].filter((val) => val === true).length + productionFields + alwaysVisibleFields;
}
function getExpandedFieldCount(workOrderLayoutSetting) {
  const expandedFieldSettings = omit(workOrderLayoutSetting, ["itemCode", "itemDescription", "__typename"]);

  return Object.values(expandedFieldSettings).filter((val) => val === true).length;
}

export { getAlwaysVisibleFieldCount, getExpandedFieldCount };
