import React, { useContext } from "react";
import debounce from "lodash/debounce";
import styled from "styled-components";
import SidebarView from "./SidebarView";
import SlideOutPanel from "./SlideOutPanel";
import UIStateContext from "contexts/UIStateContext";
import useGetWorkOrders from "hooks/apollo/workOrder/useGetWorkOrders";
import useSlideOutState from "hooks/state/useSlideOutState";
import WorkOrderSearchContext from "contexts/WorkOrderSearchContext";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";

const SlideOutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-row: 1 / 3;
  max-height: 100%;
  overflow: hidden;
`;

function SidebarContainer() {
  const { workOrders, fetch: filterWorkOrders, fetching } = useGetWorkOrders();
  const slideOutState = useSlideOutState();
  const applicationContext = useApplicationContext();
  const {
    settings: { showCustomWorkOrderFields, newSearchInput },
  } = applicationContext;

  const {
    numberOfFiltersApplied,
    setState: setFilters,
    resetState: clearFilters,
    state: { searchText, searchCategory },
  } = useContext(WorkOrderSearchContext);

  const {
    setState: setSidebarOpen,
    state: { sidebarOpen },
  } = useContext(UIStateContext);

  const handleClearFilters = () => {
    filterWorkOrders(clearFilters());
  };

  const handleChangeFilter = (filter) => {
    const filters = setFilters(filter);

    debouncedFilterWorkOrders(filters);
  };

  const debouncedFilterWorkOrders = debounce((filter) => {
    filterWorkOrders(filter);
  }, 500);

  const handleCategoryChange = (filter) => {
    setFilters(filter);

    handleChangeFilter(filter);
  };

  const toggleSidebar = () => {
    setSidebarOpen({ sidebarOpen: !sidebarOpen });
  };

  // @keep_search_fields_in_sync
  const searchCategoryOptions = [
    { value: "", label: "All" },
    { value: "code", label: "Work order code" },
    { value: "external_id", label: "Work order ID" },
    { value: "description", label: "Work order description" },
    { value: "status", label: "Work order status" },
    { value: "custom_work_order_field", label: "Custom work order field" },
    { value: "notes", label: "Notes" },
    { value: "item_code", label: "Item code" },
    { value: "item_description", label: "Item description" },
    { value: "customer", label: "Customer" },
    { value: "line_type_preference", label: "Line type preference" },
    { value: "item_class", label: "Item class" },
    { value: "item_family", label: "Item family" },
    { value: "item_type", label: "Item type" },
    { value: "item_category", label: "Item category" },
    { value: "service_category", label: "Service category" },
  ].filter(Boolean);

  const getSearchCategoryOptions = () => {
    let result = searchCategoryOptions;
    if (!showCustomWorkOrderFields) {
      result = result.filter((option) => option.value !== "custom_work_order_field");
    }
    if (newSearchInput) {
      result = result.filter((option) => option.value !== "");
    }

    return result;
  };

  return (
    <SlideOutWrapper>
      <SlideOutPanel onClearFilters={handleClearFilters} />
      <SidebarView
        searchText={searchText}
        searchCategory={searchCategory}
        numberOfFiltersApplied={numberOfFiltersApplied}
        workOrders={workOrders}
        onTextSearch={handleChangeFilter}
        onCategoryChange={handleCategoryChange}
        sidebarOpen={sidebarOpen}
        onToggleSidebar={toggleSidebar}
        slideOutState={slideOutState}
        fetching={fetching}
        searchCategoryOptions={getSearchCategoryOptions()}
        applicationContext={applicationContext}
      />
    </SlideOutWrapper>
  );
}

export default function SidebarContainerWrapper() {
  return <SidebarContainer />;
}
