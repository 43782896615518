import React from "react";
import styled from "styled-components";
import { ReactComponent as FlagA } from "./images/FlagA.svg";
import { ReactComponent as FlagB } from "./images/FlagB.svg";
import { ReactComponent as FlagC } from "./images/FlagC.svg";
import { ReactComponent as FlagD } from "./images/FlagD.svg";
import { ReactComponent as FlagE } from "./images/FlagE.svg";
import { ReactComponent as FlagF } from "./images/FlagF.svg";

const FLAG_SIZE_LARGE = "large";
const FlagWrapper = styled.div(({ sizeInPixels }) => ({
  "line-height": `${sizeInPixels}px`,
  height: `${sizeInPixels}px`,
  width: `${sizeInPixels + 8}px`,
  padding: "0 8px 0 0",
}));

export default function WorkOrderFlag({ flag, size }) {
  if (!flag) return null;

  const sizeInPixels = size === FLAG_SIZE_LARGE ? 24 : 16;

  return (
    <FlagWrapper alt={`Flag ${flag}`} data-testid="flag" sizeInPixels={sizeInPixels}>
      {flag === "A" && <FlagA display="block" sizeInPixels={sizeInPixels} />}
      {flag === "B" && <FlagB display="block" sizeInPixels={sizeInPixels} />}
      {flag === "C" && <FlagC display="block" sizeInPixels={sizeInPixels} />}
      {flag === "D" && <FlagD display="block" sizeInPixels={sizeInPixels} />}
      {flag === "E" && <FlagE display="block" sizeInPixels={sizeInPixels} />}
      {flag === "F" && <FlagF display="block" sizeInPixels={sizeInPixels} />}
    </FlagWrapper>
  );
}
