import React, { useContext } from "react";
import { QuietButton, Sidebar } from "@nulogy/components";
import FilterSidebarContainer from "./FilterSidebarContainer";
import WorkOrderDetailsContainer from "./workOrderDetails/WorkOrderDetailsContainer";
import { sidebarExpandButtonWidth } from "components/pages/schedule/SidebarExpander";
import { expandedSidebarWidth } from "components/pages/schedule/SidebarView";
import UIStateContext from "contexts/UIStateContext";
import useSlideOutState from "hooks/state/useSlideOutState";

export default function SlideOutPanel({ onClearFilters }) {
  const {
    state: { sidebarOpen },
  } = useContext(UIStateContext);
  const { isOpen, closeSlideOut: onClose, isFilterMode } = useSlideOutState();

  const showSidebar = isOpen();

  const handleCloseSlideOutPanel = () => {
    onClose();
  };

  return (
    <Sidebar
      isOpen={showSidebar}
      offset={sidebarOpen ? expandedSidebarWidth : sidebarExpandButtonWidth}
      duration={sidebarOpen && !showSidebar ? 0.5 : 0.25}
      width="410px"
      onClose={handleCloseSlideOutPanel}
      closeOnOutsideClick={false}
      overlay={false}
      title={isFilterMode() ? "Work order filters" : "Work order details"}
      closeButtonTestId={isFilterMode() ? "spec-filter-close-button" : "spec-work-order-details-close-button"}
      style={{ zIndex: 10 }}
      footer={isFilterMode() ? <QuietButton onClick={onClearFilters}>Clear filters</QuietButton> : null}
    >
      <FilterSidebarContainer />
      <WorkOrderDetailsContainer />
    </Sidebar>
  );
}
