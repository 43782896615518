import ApplicationContext from "domain/ApplicationContext";
import Bryntum from "domain/Bryntum";

export default function buildFilterQueryParameters({
  applicationContext,
  bryntumScheduler,
  visibleTimeRange,
  searchText,
  searchCategory,
  filters,
  ...extraArgs
}) {
  const scheduledBetweenInSiteTimeZone = getScheduledDateFilters({
    filterByScheduleView: filters.filterByScheduleView,
    scheduleViewDateRange: extractDateRange(visibleTimeRange),
    scheduledBetween: filters.scheduledBetween,
    applicationContext,
  });

  const filteredLineIds = () => {
    if (!filters.filterByScheduleView) return [];

    return Bryntum.filteredLineIds(bryntumScheduler);
  };

  return {
    ...extraArgs,
    searchText,
    searchCategory,
    filters: {
      ...filters,
      dueBetween: overrideTimeZone(filters.dueBetween, applicationContext),
      materialAvailabilityBetween: overrideTimeZone(filters.materialAvailabilityBetween, applicationContext),
      plannedStartBetween: overrideTimeZone(filters.plannedStartBetween, applicationContext),
      scheduledBetween: scheduledBetweenInSiteTimeZone,
      filteredLineIds: filteredLineIds(),
    },
  };
}

function extractDateRange({ startDate, endDate }) {
  return { startsAt: startDate, endsAt: endDate };
}

export function getScheduledDateFilters({
  filterByScheduleView,
  applicationContext,
  scheduleViewDateRange,
  scheduledBetween,
}) {
  if (filterByScheduleView) return overrideTimeZone(scheduleViewDateRange, applicationContext);

  return overrideTimeZone(scheduledBetween, applicationContext);
}

export function overrideTimeZone(dateRange, applicationContext) {
  if (!dateRange) return null;

  return {
    startsAt: ApplicationContext.overrideToSiteTimeZone(applicationContext, dateRange?.startsAt),
    endsAt: ApplicationContext.overrideToSiteTimeZone(applicationContext, dateRange?.endsAt),
  };
}
