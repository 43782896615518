import React from "react";
import styled from "styled-components";
import { Box, DropdownButton, DropdownMenu, Flex, Icon, IconicButton, Input, Text, theme } from "@nulogy/components";

const Border = styled(Flex)`
  border: 1px solid ${theme.colors.lightGrey};
  border-radius: ${theme.sizes.half};
  align-items: center;
  margin-bottom: ${theme.space.x2};
`;

const BorderlessInput = styled(Input)`
  input {
    border: none;
    width: 100%;
  }

  input:focus {
    border: none;
    box-shadow: none;
  }
  color: ${theme.colors.black};
`;

const VerticalDivider = styled(Box)`
  border-left: 1px solid ${theme.colors.grey};
  height: 24px;
`;

export default function WorkOrderSearch({
  searchCategoryOptions,
  searchCategory,
  searchText,
  onTextSearch,
  onCategoryChange,
}) {
  const searchCategoryName = searchCategoryOptions.find((option) => option.value === searchCategory)?.label;

  const changeSearchText = (newSearchText) => {
    onTextSearch({ searchText: newSearchText, searchCategory });
  };

  const changeSearchCategory = (newSearchCategory) => {
    onCategoryChange({ searchText, searchCategory: newSearchCategory });
  };

  const handleWorkOrderSearch = (event) => {
    const newSearchText = event?.target?.value;
    if (newSearchText === searchText) return;

    changeSearchText(newSearchText);
  };

  const clearSearch = () => {
    onCategoryChange({ searchText: "", searchCategory: "" });
  };

  return (
    <Border>
      <Icon
        width="24px"
        height="24px"
        fill={theme.colors.midGrey}
        focusable="false"
        icon="search"
        margin={theme.space.half}
      />
      {searchCategoryName && (
        <Text color={theme.colors.midGrey} whiteSpace="nowrap">
          {searchCategoryName}:
        </Text>
      )}
      <BorderlessInput
        className="spec-filter-field searchFilterInput pendo-search-filter"
        placeholder={searchCategoryName ? "" : "Search work orders..."}
        onChange={handleWorkOrderSearch}
        value={searchText}
      />
      <Flex gap={theme.space.half} style={{ alignItems: "center" }}>
        {(searchText || searchCategoryName) && (
          <IconicButton icon="close" color={theme.colors.midGrey} onClick={clearSearch} />
        )}
        {(searchText || searchCategoryName) && <VerticalDivider />}
        <DropdownMenu
          placement="bottom-end"
          showArrow={false}
          width="375px"
          trigger={() => (
            <IconicButton
              data-testid="search-options-trigger"
              icon="tune"
              color={theme.colors.midGrey}
              tooltip="Show search options"
              marginRight={theme.space.half}
            />
          )}
        >
          {searchCategoryOptions.map((option) => (
            <DropdownButton
              data-testid={`search-category-option-${option.value}`}
              key={option.value}
              onClick={() => {
                changeSearchCategory(option.value);
              }}
            >
              {option.label}
            </DropdownButton>
          ))}
        </DropdownMenu>
      </Flex>
    </Border>
  );
}
