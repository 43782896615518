import React from "react";
import { Select } from "@nulogy/components";
import { useQuery } from "@apollo/client";
import memoizeOne from "memoize-one";
import FilterLabel from "./FilterLabel";
import { GetAllCustomWorkOrderFields } from "hooks/apollo/customWorkOrderField/customWorkOrderField.gql";

const memoizedCustomWorkOrderFields = memoizeOne(buildCustomWorkOrderFieldOptions);

function buildCustomWorkOrderFieldOptions(customWorkOrderFields) {
  return customWorkOrderFields.map(({ name, color }) => ({
    label: name,
    value: name,
    color,
  }));
}

export default function CustomWorkOrderFieldSelector({ onChange, selectedCustomWorkOrderFields }) {
  const { data, refetch: refetchCustomWorkOrderFields } = useQuery(GetAllCustomWorkOrderFields);
  const customWorkOrderFields = data?.customWorkOrderFields || [];

  const customWorkOrderFieldFilterOptions = memoizedCustomWorkOrderFields(customWorkOrderFields);

  return (
    <>
      <FilterLabel value="Custom work order field" />
      <Select
        value={selectedCustomWorkOrderFields}
        className="spec-custom-work-order-field-filter-multiselect"
        multiselect
        options={customWorkOrderFieldFilterOptions}
        onMenuOpen={refetchCustomWorkOrderFields}
        onChange={onChange}
        placeholder="Select..."
        closeMenuOnSelect={false}
      />
    </>
  );
}
