import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { ScheduleWorkBlock } from "./workBlock.gql";
import { insertWorkBlock } from "./helpers/handleUpdateWorkBlocks";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import { useNotificationState } from "hooks/state";
import TimeZone from "utils/TimeZone";
import GqlError from "utils/GqlError";
import Uuid from "utils/Uuid";

export default function useScheduleWorkBlock(refetchShiftInstances) {
  const applicationContext = useApplicationContext();
  const { setNotification } = useNotificationState();
  const [scheduleWorkBlock] = useMutation(ScheduleWorkBlock, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to schedule work block",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted: () => {
      refetchShiftInstances();
    },
  });

  return (workBlock) => {
    const workBlockId = Uuid.generate();

    const workBlockForOptimisticResponse = {
      id: workBlockId,
      effectiveLabor: 10,
      efficiencyOverride: null,
      laborOverride: null,
      productionRateDependsOnNumberOfPeople: true,
      productionRateOverride: null,
      performanceOverride: null,
      quantity: 640,
      standardPeople: 10,
      unitOfMeasure: null,
      lineEfficiency: 1,
      standardProductionRate: workBlock.workOrder.standardProductionRate,
      performance: workBlock.workOrder.performance,
      ...workBlock,
    };

    return scheduleWorkBlock({
      variables: {
        id: workBlockId,
        workTimeStartsAt: TimeZone.overrideZone(workBlock.workTimeStartsAt, applicationContext.timeZone.name),
        workTimeEndsAt: TimeZone.overrideZone(workBlock.workTimeEndsAt, applicationContext.timeZone.name),
        lineId: workBlock.line.id,
        workOrderId: workBlock.workOrder.id,
        lockedOnSchedule: workBlock.lockedOnSchedule,
        teardownTimeLabel: workBlock.teardownTimeLabel || "Teardown Time",
        teardownTimeDuration: workBlock.teardownTimeDuration || 0,
        productionRateOverride: workBlock.productionRateOverride,
        performanceOverride: workBlock.performanceOverride,
        efficiencyOverride: workBlock.efficiencyOverride,
        laborOverride: workBlock.laborOverride,
      },
      optimisticResponse: {
        scheduleWorkBlock: {
          workBlock: workBlockForOptimisticResponse,
          __typename: "ScheduleWorkBlockPayload",
        },
      },
      update: (proxy, { data }) => {
        const newWorkBlock = data.scheduleWorkBlock.workBlock;

        insertWorkBlock(newWorkBlock, proxy);
      },
    });
  };
}
