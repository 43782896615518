import { theme } from "@nulogy/components";
import styled from "styled-components";

const Block = styled.div`
  flex: 0 1 auto;
  overflow: hidden;
  font-size: 14px;
  max-width: fit-content;
  position: sticky;
  left: 0;
  padding: ${theme.sizes.x1} 0 ${theme.sizes.x1} ${theme.sizes.x1};
`;

export default Block;
