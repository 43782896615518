import React from "react";
import { Box, Textarea } from "@nulogy/components";
import Field from "components/common/Field";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import PackmanagerWorkOrderLink from "components/pages/schedule/PackmanagerWorkOrderLink";
import {
  formatDateTime,
  formatDurationInHours,
  formatEfficiency,
  formatLabor,
  formatPerformance,
  formatQuantity,
  formatProductionRate as rawFormatProductionRate,
} from "components/helpers/format";
import ProductionDetailsSummary from "components/pages/schedule/workBlocks/ProductionDetailsSummary";
import BlockModal from "components/pages/schedule/styles/BlockModal";
import Section from "components/common/Section";
import FieldRow from "components/common/FieldRow";
import PackmanagerJobLink from "components/pages/schedule/PackmanagerJobLink";
import WorkBlock from "domain/WorkBlock";
import DateTime from "utils/DateTime";

const TextArea = ({ value }) => (
  <Box marginBottom="x2">
    <Textarea disabled value={value || ""} rows={5} />
  </Box>
);

export default function ReadOnlyWorkBlockModalView({
  isOpen,
  onClose,
  blockData,
  effectiveProductionRate,
  workBlockQuantity,
  productionRateLabel,
  workBlockLayout,
}) {
  const {
    block,
    block: {
      workOrder: { notes, unitOfMeasure, code, externalUuid },
      line: { name: lineName },
      jobId,
      standardProductionRate,
      standardPeople,
      productionRateOverride,
      performance,
      performanceOverride,
      efficiencyOverride,
      laborOverride,
      lineEfficiency,
      teardownTimeLabel,
      teardownTimeDuration,
      workTimeStartsAt,
      workTimeEndsAt,
    },
  } = blockData;

  const applicationContext = useApplicationContext();
  const description = WorkBlock.description(block, workBlockLayout);
  const formatProductionRate = (productionRate) => rawFormatProductionRate({ productionRate, unitOfMeasure });

  return (
    <BlockModal title="Work block" isOpen={isOpen} onRequestClose={onClose} className="spec-block-modal">
      <Section title="Scheduling details">
        <Field labelText="Work order" className="spec-selected-work-order">
          {description && `${description} • `}
          <PackmanagerWorkOrderLink code={code} externalUuid={externalUuid} />
        </Field>
        {jobId && (
          <FieldRow data-testid="published-job">
            <Field labelText="Job">
              <PackmanagerJobLink jobId={jobId} applicationContext={applicationContext} />
            </Field>
          </FieldRow>
        )}
        <Field labelText="Line">{lineName}</Field>
        <FieldRow>
          <Field labelText="Work starts at">{formatDateTime(workTimeStartsAt)}</Field>
          <Field labelText="Work ends at">{formatDateTime(workTimeEndsAt)}</Field>
        </FieldRow>
      </Section>
      <Section title="Teardown time" className="spec-teardown-time-section">
        <FieldRow>
          <Field labelText="Teardown label">{teardownTimeLabel}</Field>
          <Field labelText="Teardown duration">{formatDurationInHours(teardownTimeDuration)}</Field>
        </FieldRow>
        {teardownTimeDuration > 0 && (
          <FieldRow>
            <Field labelText="Teardown time starts at">{formatDateTime(workTimeEndsAt)}</Field>
            <Field labelText="Teardown time ends at">
              {formatDateTime(DateTime.addDuration(workTimeEndsAt, teardownTimeDuration, "seconds"))}
            </Field>
          </FieldRow>
        )}
      </Section>
      <Section title="Production details">
        <FieldRow>
          <Field labelText={productionRateLabel}>{formatProductionRate(standardProductionRate)}</Field>
          <Field labelText="Production rate override">{formatProductionRate(productionRateOverride)}</Field>
        </FieldRow>
        <FieldRow>
          <Field labelText="Performance">{formatPerformance(performance)}</Field>
          <Field labelText="Performance override">{formatPerformance(performanceOverride)}</Field>
        </FieldRow>
        <FieldRow>
          <Field labelText="Standard people">{formatQuantity(standardPeople)}</Field>
          <Field labelText="Labor override">{formatLabor(laborOverride)}</Field>
        </FieldRow>
        <FieldRow>
          <Field labelText="Efficiency on line">{formatEfficiency(lineEfficiency)}</Field>
          <Field labelText="Efficiency override">{formatEfficiency(efficiencyOverride)}</Field>
        </FieldRow>
        <ProductionDetailsSummary
          effectiveProductionRate={formatQuantity(effectiveProductionRate)}
          expectedQuantity={formatQuantity(workBlockQuantity)}
          unitOfMeasure={unitOfMeasure}
        />
      </Section>

      <Section title="Work order notes">
        <TextArea value={notes} />
      </Section>
    </BlockModal>
  );
}
