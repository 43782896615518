import React from "react";
import { Box, Flex, Icon, theme } from "@nulogy/components";
import styled from "styled-components";
import formatLabor from "./formatLabor";
import DueDate from "./DueDate";
import BlockContainer from "./BlockContainer";
import Block from "./Block";
import BlockCellName from "./BlockCellName";
import LockIcon from "./LockIcon";
import BlockCellDetails from "./BlockCellDetails";
import BlockCellMenu from "./BlockCellMenu";
import CustomWorkOrderField from "./CustomWorkOrderField";
import FillInGapTip from "./FillInGapTip";
import WorkBlockHeader from "./WorkBlockHeader";
import WorkOrderFlag from "./WorkOrderFlag";
import { formatDuration, formatQuantity } from "components/helpers/format";
import NotesIndicator from "components/pages/schedule/NotesIndicator";
import Tag from "components/pages/schedule/Tag";
import WorkBlockDomain from "domain/WorkBlock";
import WorkOrder from "domain/WorkOrder";

const QuickActionButtons = styled.div`
  position: fixed;
  bottom: 0px;
  right: 12px;
  cursor: pointer;
  height: 30px;
  background-color: ${theme.colors.white};
  border-radius: 5px;
  margin-bottom: ${theme.space.half};
  display: ${({ hidden }) => (hidden ? "none" : "block")};
  z-index: 10;
  border: 1px solid ${theme.colors.grey};
  padding: 2px;
  box-shadow: ${theme.shadows.medium};
`;

const TeardownTime = styled.div`
  background-image: repeating-linear-gradient(135deg, #ddd, #ddd 10px, #eee 10px, #eee 20px);
  height: 100%;
  color: ${theme.colors.black} !important; // Needed to fight with Bryntum over text colors
  padding-top: ${theme.space.x1};
  padding-left: ${theme.space.x1};
  padding-right: ${theme.space.x1};
  text-overflow: ellipsis;
  font-weight: ${theme.fontWeights.medium};
  position: relative;
  overflow: hidden;
  z-index: 2; // Keeps kabob under teardown time when very small
`;

export default function WorkBlock({ blockData, applicationContext, readonly, teardownWidth = 0 }) {
  const {
    block,
    block: {
      id,
      lockedOnSchedule,
      laborOverride,
      effectiveLabor,
      jobId,
      standardProductionRate,
      teardownTimeLabel,
      teardownTimeDuration,
      workOrder,
      workOrder: { code, flag, notes, customWorkOrderFieldColor, customWorkOrderFieldName, unitOfMeasure },
    },
  } = blockData;
  const {
    customerSettings: { workBlockLayout },
  } = applicationContext;

  const formattedTeardownTimeDuration = formatDuration(teardownTimeDuration);
  const teardownDescription = `${teardownTimeLabel} (${formattedTeardownTimeDuration})`;
  const workOrderCardStatus = WorkOrder.cardStatus(workOrder);
  const workBlockQuantity = formatQuantity(WorkBlockDomain.quantity(block));
  const jobIdMessage = `Job ${jobId}`;

  return (
    <>
      <BlockContainer
        readonly={readonly}
        data-standard-production-rate={standardProductionRate}
        teardownWidth={teardownWidth}
        className={`spec-work-block-${id} spec-work-block-${workOrderCardStatus}`}
      >
        <Block>
          {notes && <NotesIndicator />}
          <Flex flexDirection="column" gap="2px">
            <BlockCellName>
              {lockedOnSchedule && (
                <LockIcon>
                  <Icon display="block" icon="lock" size="16px" />
                </LockIcon>
              )}
              <CustomWorkOrderField
                applicationContext={applicationContext}
                customWorkOrderFieldName={customWorkOrderFieldName}
                customWorkOrderFieldColor={customWorkOrderFieldColor}
              />
              <WorkBlockHeader block={block} code={code} workBlockLayout={workBlockLayout} />
            </BlockCellName>
            <BlockCellDetails>
              <DueDate workBlock={blockData.block} />
              <Box>
                {formatLabor(laborOverride, effectiveLabor)}
                {workBlockQuantity ? ` • ${workBlockQuantity} ${unitOfMeasure} expected` : ""}
              </Box>
            </BlockCellDetails>
          </Flex>
          <Flex alignItems="center" pt={theme.sizes.x1}>
            <WorkOrderFlag flag={flag} />
            {jobId ? <Tag>{jobIdMessage}</Tag> : null}
          </Flex>
        </Block>

        <BlockCellMenu className="b-fa b-fa-ellipsis-v js-block-menu spec-work-block-menu pendo-work-block-menu" />

        {teardownWidth > 0 && <TeardownTime className="teardown-time">{teardownDescription}</TeardownTime>}
      </BlockContainer>
      <Box position="absolute">
        <FillInGapTip />
        <QuickActionButtons
          id={`work-block-${id}-quick-actions`}
          className={`quick-actions hidden spec-work-block-${id}-quick-actions`}
        >
          <Icon
            icon="stickyNote"
            color={theme.colors.darkBlue}
            className="edit-notes-icon pendo-edit-notes-quick-action"
          />
          <Box
            display="inline"
            marginX="2px"
            borderRight={`1px solid ${theme.colors.grey}`}
            position="relative"
            bottom="7px"
          />
          <Icon
            icon="fitScreen"
            color={theme.colors.darkBlue}
            className="fully-schedule-icon pendo-fully-schedule-quick-action"
          />
        </QuickActionButtons>
      </Box>
    </>
  );
}
