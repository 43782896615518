import React from "react";
import { Box, Icon, Tooltip, theme } from "@nulogy/components";
import styled from "styled-components";
import { NO_DATA_LABEL, formatDate, formatDuration, formatQuantity } from "components/helpers/format";
import ProductionProgressBar from "components/pages/schedule/workOrderCards/ProductionProgressBar";
import WorkOrder from "domain/WorkOrder";
import TruncatedText from "components/common/TruncatedText";
import ThinDivider from "components/common/ThinDivider";

const Information = styled(Box)`
  display: grid;
  grid-template-columns: 108px auto;
  column-gap: 2px;
  row-gap: 2px;
  max-height: ${({ hidden = false }) => (hidden ? "0px" : "400px")};
  overflow: hidden;
  //transition: max-height 0.2s ease-in-out;
`;

const ErrorWrapper = styled.div`
  color: ${theme.colors.red};
  height: 16px;
  vertical-align: middle;
  line-height: 16px;
`;

const ErrorSpan = styled.span`
  position: relative;
  bottom: 4px;
  margin-left: 3px;
`;

const ErrorTooltip = styled(Tooltip)({
  whiteSpace: "normal",
  textAlign: "left",
});

const QuantityError = ({ prefix }) => (
  <ErrorWrapper>
    <ErrorTooltip tooltip="This work order is missing data and cannot be scheduled.">
      <Icon icon="error" size="16px" />
    </ErrorTooltip>
    <ErrorSpan>{prefix} is 0</ErrorSpan>
  </ErrorWrapper>
);
const FieldInformation = ({ label, value, settingKey, settings }) => {
  const dataTestIdValue = `${label.replace(/\s/g, "-").toLowerCase()}-value`;
  const dataTestIdLabel = `${label.replace(/\s/g, "-").toLowerCase()}-label`;

  if (settings && !settings[settingKey]) return null;

  return (
    <>
      <TruncatedText fontSize="12px" color={theme.colors.midGrey} title={label} data-testid={dataTestIdLabel}>
        {label}
      </TruncatedText>
      <TruncatedText fontSize="12px" title={typeof value === "string" ? value : null} data-testid={dataTestIdValue}>
        {value ?? NO_DATA_LABEL}
      </TruncatedText>
    </>
  );
};
const getPercentageLabel = ({ producedQuantity, quantity }) => {
  if (producedQuantity && !quantity) return "% cannot be calculated";
  if (!quantity || !producedQuantity) return "0% complete";

  const roundedProducedQuantity = formatQuantity(producedQuantity);
  const roundedQuantity = formatQuantity(quantity);
  const producedPercentage = Math.min(Math.floor((roundedProducedQuantity / roundedQuantity) * 100), 100);

  return `${producedPercentage}% complete`;
};

const ToBeScheduledValue = ({ workOrder }) => {
  const { unscheduledQuantity, unitOfMeasure, quantity, remainingDuration, standardProductionRate } = workOrder;
  const roundedUnscheduledQuantity = WorkOrder.isScheduledCompletedOrCancelled(workOrder)
    ? 0
    : formatQuantity(Math.abs(unscheduledQuantity));
  const remainingTime = WorkOrder.isScheduledCompletedOrCancelled(workOrder)
    ? formatDuration(0)
    : formatDuration(remainingDuration);

  return (
    <>
      {quantity > 0 &&
        standardProductionRate > 0 &&
        `${remainingTime} • ${roundedUnscheduledQuantity} ${unitOfMeasure}`}
      {standardProductionRate <= 0 && <QuantityError prefix="Production rate" />}
      {quantity <= 0 && <QuantityError prefix="Work order quantity" />}
    </>
  );
};

const ToBeScheduledField = ({ workOrder }) => {
  const toBeScheduledLabel = WorkOrder.isOverscheduled(workOrder) ? "Overscheduled by" : "To be scheduled";

  return <FieldInformation label={toBeScheduledLabel} value={<ToBeScheduledValue workOrder={workOrder} />} />;
};

export default function WorkOrderCardInformation({ workOrder, layoutSetting, expanded, expandedFields }) {
  const roundedQuantity = formatQuantity(workOrder.quantity);
  const roundedProducedQuantity = formatQuantity(workOrder.producedQuantity);
  const roundedScheduledQuantity = formatQuantity(workOrder.scheduledQuantity);

  const scheduledTime = formatDuration(workOrder.scheduledDuration);
  const percentageLabel = getPercentageLabel({
    producedQuantity: workOrder.producedQuantity,
    quantity: workOrder.quantity,
  });

  return (
    <Box>
      <Information paddingRight="10px">
        <FieldInformation label="Due date" value={formatDate(workOrder.due)} />
        <FieldInformation label="Item code" value={workOrder.itemCode} settingKey="itemCode" settings={layoutSetting} />
        <FieldInformation
          label="Item description"
          value={workOrder.itemDescription}
          settingKey="itemDescription"
          settings={layoutSetting}
        />
      </Information>
      <Information hidden={!expanded}>
        {expandedFields.map(({ label, value, settingKey }) => (
          <FieldInformation key={label} label={label} value={value} settingKey={settingKey} settings={layoutSetting} />
        ))}
      </Information>
      <ThinDivider />
      <Information>
        <ToBeScheduledField workOrder={workOrder} />
        <FieldInformation
          label="Scheduled"
          value={`${scheduledTime} • ${roundedScheduledQuantity} ${workOrder.unitOfMeasure}`}
        />
        <FieldInformation
          label="Produced"
          value={`${roundedProducedQuantity}/${roundedQuantity} ${workOrder.unitOfMeasure} • ${percentageLabel}`}
        />
        <ProductionProgressBar workOrder={workOrder} />
      </Information>
    </Box>
  );
}
