import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import { Card as NDSCard, theme } from "@nulogy/components";
import Authorization from "policies/Authorization";
import useSlideOutState from "hooks/state/useSlideOutState";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";

const Card = styled(NDSCard)`
  padding: 8px;
  border: solid 1px ${theme.colors.grey};
  background-color: ${theme.colors.white};

  &.non-draggable:hover,
  &.non-draggable:active {
    cursor: default;
  }
  :hover {
    box-shadow: ${theme.shadows.medium};
  }
  &.focused {
    box-shadow: ${theme.shadows.focus};
  }
`;
export default function DraggableWorkOrderCard({ workOrder, children }) {
  const slideOutState = useSlideOutState();
  const applicationContext = useApplicationContext();
  const focused = slideOutState.workOrderId === workOrder.id ? "focus" : null;
  const policy = Authorization.usePolicy();
  const userCanUpdate = policy.canUpdate(Authorization.EntityTypes.WorkOrder);
  const draggability = workOrder.schedulable && userCanUpdate ? "draggable" : "non-draggable";
  const workOrderCodeKebabCase = workOrder.code.replace(/\s/g, "-").toLowerCase();

  if (applicationContext.loading) return null;

  const { settings } = applicationContext;

  return (
    <div
      data-drag-remaining-duration={workOrder.remainingDuration || ""}
      data-drag-standard-production-rate={workOrder.standardProductionRate}
      data-drag-performance={workOrder.performance}
      data-drag-unscheduled-quantity={workOrder.unscheduledQuantity}
      data-drag-settings={JSON.stringify(settings)}
      data-work-order-id={workOrder.id}
      data-drag-work-order={JSON.stringify(workOrder)}
      data-drag-application-context={JSON.stringify(applicationContext)}
      className={classNames(
        "spec-drag-root",
        draggability,
        `pendo-work-order-card-${workOrderCodeKebabCase}`,
        "pendo-work-order-card",
      )}
      data-testid="work-order-card"
    >
      <Card
        className={classNames({
          "non-draggable": !workOrder.schedulable || !userCanUpdate,
          focused,
        })}
      >
        {children}
      </Card>
    </div>
  );
}
