import React from "react";
import { useQuery } from "@apollo/client";
import { Page } from "@nulogy/components";
import useGoBack from "hooks/useGoBack";
import LayoutSetting from "components/pages/site/LayoutSetting";
import Form from "components/controls/Form";
import FormButtons from "components/controls/FormButtons";
import { GetLayoutSetting } from "hooks/apollo/customerSettings/customerSettings.gql";
import useUpdateCustomerSettings from "hooks/apollo/customerSettings/useUpdateCutomerSettings";
import paths from "config/routePaths";
import StickyFooter from "components/controls/StickyFooter";

export default function CustomerSettings() {
  const { goBack } = useGoBack();

  const { data, loading } = useQuery(GetLayoutSetting);

  const leavePage = () => {
    goBack(paths.home);
  };

  const updateCustomerSettings = useUpdateCustomerSettings({
    onCompleted: leavePage,
  });
  if (loading) return null;

  const initialValues = {
    workBlockLayout: data?.customerSettings.workBlockLayout,
    workOrderCardLayout: data?.workOrderLayoutSetting,
  };

  const handleSave = (layoutSetting) => updateCustomerSettings(layoutSetting);

  return (
    <Page title="Layout" className="spec-customer-settings" data-testid="title">
      <Form initialValues={initialValues} onSave={handleSave}>
        <LayoutSetting />
        <StickyFooter>
          <FormButtons onCancel={leavePage} />
        </StickyFooter>
      </Form>
    </Page>
  );
}
