import { useMutation } from "@apollo/client";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { UpdateLayoutSetting } from "./customerSettings.gql";
import { useNotificationState } from "hooks/state";
import GqlError from "utils/GqlError";

export default function useUpdateCustomerSettings({ onCompleted }) {
  const { setNotification } = useNotificationState();
  const [updateCustomerSettings] = useMutation(UpdateLayoutSetting, {
    onError: (error) => {
      GqlError.handler(error, (errors) => {
        const allErrors = flatten(values(errors)).join("\n");

        setNotification({
          title: "Unable to update layout settings",
          message: allErrors,
          type: "danger",
        });
      });
    },
    onCompleted,
  });

  return (customerSettings) => {
    const { workBlockLayout, workOrderCardLayout } = customerSettings;

    // eslint-disable-next-line no-unused-vars
    const { __typename, ...layoutProps } = workOrderCardLayout;

    return updateCustomerSettings({
      variables: {
        workBlockLayout,
        ...layoutProps,
      },
    });
  };
}
