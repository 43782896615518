import React from "react";
import styled from "styled-components";
import CommonIndicator from "components/common/CustomWorkOrderFieldIndicator";

const Indicator = styled(CommonIndicator)`
  position: relative;
  vertical-align: middle;
  margin-right: 6px;
  margin-left: 2px;
`;

export default function CustomWorkOrderField({
  applicationContext,
  customWorkOrderFieldName,
  customWorkOrderFieldColor,
}) {
  const {
    settings: { showCustomWorkOrderFields },
  } = applicationContext;

  if (!customWorkOrderFieldName || !showCustomWorkOrderFields) return null;

  return <Indicator color={customWorkOrderFieldColor} title={customWorkOrderFieldName} />;
}
