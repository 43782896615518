import { theme } from "@nulogy/components";
import styled from "styled-components";

const BlockCellMenu = styled.div`
  cursor: pointer;
  padding-top: ${theme.sizes.x1};
  padding-left: 6px;
  padding-right: 6px;
`;

export default BlockCellMenu;
