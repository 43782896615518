import React from "react";
import { Box, Flex, Icon, IconicButton, Text, theme } from "@nulogy/components";
import styled from "styled-components";
import CustomWorkOrderFieldIndicator from "components/pages/schedule/workOrderCards/CustomWorkOrderFieldIndicator";
import TruncatedText from "components/common/TruncatedText";
import UnstyledLink from "components/common/UnstyledLink";
import WorkOrderCardMenu from "components/pages/schedule/workOrderCards/WorkOrderCardMenu";
import Authorization from "policies/Authorization";
import useDeleteWorkOrder from "hooks/apollo/workOrder/useDeleteWorkOrder";
import useUnscheduleWorkOrder from "hooks/apollo/workOrder/useUnscheduleWorkOrder";
import useSlideOutState from "hooks/state/useSlideOutState";
import useApplicationContext from "hooks/apollo/applicationContext/useApplicationContext";
import useUpdateWorkOrder from "hooks/apollo/workOrder/useUpdateWorkOrder";

const HeaderContainer = styled(Flex)`
  flex-grow: 1;
  overflow: hidden; // allow the item to shrink to fit inside the container
`;

const DragIcon = styled.div(({ schedulable }) => {
  const color = schedulable ? theme.colors.darkBlue : theme.colors.grey;

  return {
    marginLeft: "-4px",
    color,

    svg: {
      verticalAlign: "middle",
    },
  };
});
export default function WorkOrderCardHeader({
  workOrder,
  expandedFields,
  layoutSetting,
  onToggleExpandWorkOrder,
  expanded,
  showOptions = true,
}) {
  const removeWorkOrder = useDeleteWorkOrder();
  const handleUnscheduleWorkOrder = useUnscheduleWorkOrder();
  const updateWorkOrder = useUpdateWorkOrder();
  const policy = Authorization.usePolicy();
  const slideOutState = useSlideOutState();
  const applicationContext = useApplicationContext();

  if (applicationContext.loading) return null;

  const anyExpandableFields = expandedFields.some(({ settingKey }) => layoutSetting[settingKey]);

  const userCanUpdate = policy.canUpdate(Authorization.EntityTypes.WorkOrder);

  const { settings } = applicationContext;

  const handleOpenWorkOrderDetails = () => {
    slideOutState.openWorkOrderDetails(workOrder.id);
  };

  const handleRemoveWorkOrder = () => {
    const areDetailsOpen = workOrder.id === slideOutState.workOrderId;
    if (areDetailsOpen) {
      slideOutState.closeSlideOut();
    }

    removeWorkOrder({ workOrderId: workOrder.id });
  };

  return (
    <Flex flexDirection="row" alignItems="center" gap="half" height="x3">
      {userCanUpdate && (
        <DragIcon schedulable={workOrder.schedulable}>
          <Icon icon="drag" />
        </DragIcon>
      )}
      <CustomWorkOrderFieldIndicator
        settings={settings}
        name={workOrder.customWorkOrderFieldName}
        color={workOrder.customWorkOrderFieldColor}
        userCanUpdate={userCanUpdate}
      />
      <HeaderContainer flex="1" alignItems="center" gap="half" height="x3" justifyContent="flex-start">
        <TruncatedText
          inline
          fontSize="small"
          lineHeight={theme.lineHeights.smallTextCompressed}
          className={`spec-work-order-${workOrder.id} pendo-view-work-order-details-work-order-card-code`}
          title={workOrder.code}
          maxWidth="70%"
        >
          <UnstyledLink draggable="false" fontSize="small" onClick={handleOpenWorkOrderDetails}>
            {workOrder.code}
          </UnstyledLink>
        </TruncatedText>
        <Text inline fontWeight="bold" marginX="4px" color="midGrey">
          •
        </Text>
        <TruncatedText
          inline
          fontSize="small"
          title={workOrder.customerName}
          lineHeight={theme.lineHeights.smallTextCompressed}
        >
          {workOrder.customerName}
        </TruncatedText>
      </HeaderContainer>
      {anyExpandableFields && (
        <Box mr="-2px">
          <IconicButton
            data-testid="expand-work-order-button"
            type="button"
            icon={expanded ? "collapse" : "expand"}
            onClick={() => {
              onToggleExpandWorkOrder(workOrder.id);
            }}
          />
        </Box>
      )}

      {showOptions && (
        <Box mr="-5px">
          <WorkOrderCardMenu
            onOpenWorkOrderDetails={handleOpenWorkOrderDetails}
            onUnscheduleWorkOrder={handleUnscheduleWorkOrder}
            onRemoveWorkOrder={handleRemoveWorkOrder}
            updateWorkOrder={updateWorkOrder}
            workOrder={workOrder}
            userCanUpdate={userCanUpdate}
            customiseWorkOrderCards
          />
        </Box>
      )}
    </Flex>
  );
}
