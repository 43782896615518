import React, { Component } from "react";
import deepmerge from "deepmerge";
import filter from "lodash/filter";
import pickBy from "lodash/pickBy";
import LocalStorage, { WorkOrderSearchSerializer } from "utils/LocalStorage";

const WorkOrderSearchContext = React.createContext({});

export const DEFAULT_SEARCH_STATE = {
  filters: {
    filterByScheduleView: false,
    showScheduledCompletedOrCancelled: false,
    showMissingData: false,
    scheduledBetween: {
      startsAt: null,
      endsAt: null,
    },
    dueBetween: {
      startsAt: null,
      endsAt: null,
    },
    materialAvailabilityBetween: {
      startsAt: null,
      endsAt: null,
    },
    plannedStartBetween: {
      startsAt: null,
      endsAt: null,
    },
    customers: [],
    customWorkOrderFields: [],
    statuses: [],
    workOrderCode: "",
    externalId: "",
    itemDescription: "",
    itemCode: "",
  },
};

export function getNumberOfFiltersApplied(filters) {
  const isBlank = (value) => !value;
  const isPresent = (value) => !!value;

  return filter(filters, (value, _) => {
    if (isBlank(value)) {
      return false;
    } else if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === "object") {
      return isPresent(value.startsAt) || isPresent(value.endsAt);
    } else return true;
  }).length;
}

export class WorkOrderSearchProvider extends Component {
  constructor(props) {
    super(props);
    const { sessionIdentifier } = props;
    this.localStorage = new LocalStorage(WorkOrderSearchSerializer);
    this.dataStoreKey = `${sessionIdentifier}-searchState`;
    const searchParams = sanitizeSearchParams(this.localStorage.read(this.dataStoreKey));
    this.state = mergeState(DEFAULT_SEARCH_STATE, searchParams);
    this.currentState = this.state;
  }

  // Fetches current application state synchronously.
  // This is useful if you need to access current application state before setState async updates
  getCurrentState = () => this.currentState;

  updateState = (newState) => {
    const oldState = this.currentState;
    this.currentState = mergeState(oldState, newState);
    this.localStorage.write(this.dataStoreKey, this.currentState);
    this.setState(this.currentState);

    return this.currentState;
  };

  resetState = () => this.updateState(DEFAULT_SEARCH_STATE);

  contextValue = () => ({
    state: this.state,
    setState: this.updateState,
    getState: this.getCurrentState,
    resetState: this.resetState,
    numberOfFiltersApplied: getNumberOfFiltersApplied(this.state.filters),
  });

  render() {
    return (
      <WorkOrderSearchContext.Provider value={this.contextValue()}>
        {this.props.children}
      </WorkOrderSearchContext.Provider>
    );
  }
}

function sanitizeSearchParams(searchParams) {
  const isValid = (_filterValue, filterName) => filterName in DEFAULT_SEARCH_STATE.filters;
  const sanitizedFilters = pickBy(searchParams.filters, isValid);

  return { ...searchParams, filters: sanitizedFilters };
}

export default WorkOrderSearchContext;

export function mergeState(object1, object2) {
  return deepmerge(object1, object2, {
    arrayMerge: (_oldArray, newArray) => newArray,
  });
}
